.testimonials-section {
	background: var(--black);
	overflow: visible;
	z-index: 10;
	padding-top: ac(64px, 36px);
	padding-bottom: ac(64px, 58px);
	clip-path: inset(-300px 0 -300px 0);

	&__wrap {
	}

	/*&:after {
		content: "";
		background: var(--white);
		position: absolute;
		z-index: 3;
		bottom: -1px;
		right: -1px;
		left: -1px;
		clip-path: polygon(0 98%, 100% 0%, 100% 100%, 0 100%);
		height: calc((64px * 2) + 2px);
	}*/

	.testimonials-line {
		padding-top: ac(24px, 14px);
		padding-bottom: ac(26px, 20px);
		background: var(--gradient-second);
		/*display: flex;
		justify-content: center;
		align-items: center;*/
		display: flex;
		align-items: center;
		width: 100%;
		transform: rotate(-5deg);
		position: relative;
		z-index: 2;

		&.rotate-5 {
			transform: rotate(5deg);
		}

		&:after,
		&:before {
			position: absolute;
			inset: 0;
			content: "";
			background: #5edff7;
			z-index: -1;
		}

		&:before {
			transform: translateX(-99%);

			@mixin mob {
				transform: translateX(-99%);
			}
		}

		&:after {
			transform: translateX(99%);
		}

		&__bg {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%) translateY(99%);
			height: 300px;
			background: var(--white);
			width: 200%;
		}

		&__text {
			text-align: center;
		}

		&__item {
			margin-left: ac(60px, 32px);
			margin-right: ac(60px, 32px);
			max-width: 92vw;
		}

		&__wrap {
			.js-marquee-wrapper {
				display: flex;
				align-items: center;
			}

			.js-marquee {
				display: flex;
				align-items: center;
			}
		}

		@mixin mob-xl {
			background: var(--blue);

			&:after,
			&:before {
				background: var(--blue);
			}
		}

		@mixin mob {
			.blockquote-content {
				p,
				li {
					font-size: 14px;
					line-height: 24px;
				}
			}
		}
	}

	&.internal-testimonials {
		background: var(--snow);
		.testimonials-line__bg {
			background: var(--snow);
		}
	}
}

.extended-testimonials-section {
	padding-top: ac(190px, 160px);
	padding-bottom: ac(127px, 60px);
	overflow: visible;
	background: var(--black);
	z-index: 2;
	clip-path: polygon(
		0 -10px,
		100% -10px,
		100% 100%,
		0 calc(100% - ac(127px, 60px))
	);

	@mixin min-media 1450 {
		clip-path: polygon(0 -10px, 100% -10px, 100% 100%, 0 calc(100% - 8.82vw));
	}

	@mixin tab-sm {
		clip-path: polygon(0 -10px, 100% -10px, 100% 100%, 0 calc(100% - 8.98vw));
	}

	@mixin min-media 1530 {
		padding-top: 230px;
	}

	.cont {
		position: relative;
	}

	.line-title {
		position: absolute;
		top: ac(-204px, -180px);
		left: 0;
		z-index: 1;
		text-align: center;
		justify-content: center;
		background: var(--snow);

		&__bg {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%) translateY(-99%);
			height: 300px;
			background: var(--snow);
			width: 200%;
		}
	}
}
