.our-team-section {
	background: var(--black);
	padding-top: ac(232px, 180px);
	padding-bottom: ac(247px, 80px);

	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - ac(127px, 60px)));

	@mixin min-media 1450 {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 8.82vw));
	}

	@mixin tab-sm {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 8.98vw));
	}

	@mixin min-media 1600 {
		padding-top: 250px;
	}

	.cont {
		position: relative;
	}

	.line-title {
		position: absolute;
		top: ac(-160px, -122px);
		left: 0;
		z-index: 1;
		text-align: left;
		justify-content: space-between;
	}

	+ .splice-section {
		display: block;
		background: var(--black);

		&:after {
			background: var(--snow);
			clip-path: polygon(0 0, 0% 100%, 100% 100%);
		}
	}

	&__wrap {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
	}

	.teams-slider {
		width: 100%;

		+ .btn {
			margin-top: ac(44px, 32px);
		}
	}
}

.teams-slider-buttons {
	margin-right: 0;
}

.meet-team-section {
	padding-top: ac(222px, 160px);
	padding-bottom: ac(116px, 40px);
	overflow: hidden;
	background: var(--snow);

	@mixin min-media 1600 {
		padding-top: 250px;
	}

	.cont {
		position: relative;
	}

	.line-title {
		position: absolute;
		top: ac(-160px, -130px);
		left: 0;
		z-index: 1;
		text-align: center;
		justify-content: center;

		&__bg {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%) translateY(-99%);
			height: 300px;
			background: var(--black);
			width: 200%;
		}
	}

	&__wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}

	&__container {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(4, minmax(0, 1fr));
		grid-column-gap: ac(32px, 16px);
		grid-row-gap: ac(64px, 32px);

		@mixin media 1100 {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}

		@mixin media 810 {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@mixin mob-lg {
			max-width: 340px;
			margin: 0 auto;
			grid-template-columns: repeat(1, minmax(0, 1fr));
		}

		.team-card__content {
			flex: 1;
		}

		.team-card__socials {
			margin-top: auto;
		}
	}

	.pagination {
		margin-top: ac(64px, 40px);
	}
}
