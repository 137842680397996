.clients-slider {
	width: 100%;
	overflow: hidden;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	position: relative;

	&:not(.swiper-initialized) {
		.swiper-wrapper {
			display: grid;
			grid-template-columns: repeat(4, minmax(0, 1fr));
			grid-column-gap: ac(32px, 16px);
			grid-row-gap: ac(40px, 20px);
		}

		.clients-slider-buttons {
			display: none;
		}
	}

	&.swiper-initialized {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}
}

.clients-slider-buttons {
	justify-content: center;
	.slider-btn {
		margin-top: 24px;

		&.swiper-button-lock {
			display: none;
		}
	}
}
