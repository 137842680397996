.article-section {
	padding-top: ac(90px, 60px);
	padding-bottom: ac(210px, 140px);

	@mixin mob {
		padding-bottom: 90px;
	}

	&__wrap {
		position: relative;
		z-index: 2;
	}

	&__decor {
		@mixin aspect-ratio 991, 1076;
		width: ac(656px, 400px);
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateX(45%) translateY(-50%);
		overflow: visible;
		mask-image: none;
		z-index: -1;
		img {
			object-fit: contain;

			&.blur-up {
				transition: filter 0.4s, transform 0.1s ease;
			}
		}
	}

	&__content {
		width: 100%;
		max-width: 1100px;
		margin-right: auto;
	}
}
