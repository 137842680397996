html {
	font-size: 16px;
	scrollbar-width: thin;
	scrollbar-color: var(--blue) #3c3c3b;
}

[data-aos="progress-anim"] {
	opacity: 0;
	transition: transform 0.3s ease-in-out, opacity 0.5s ease;

	&.aos-animate {
		opacity: 1;
		transform: translateX(0);
	}
}

.fp-watermark {
	display: none;
}

body {
	position: relative;
	min-width: 320px;
	margin: auto;
	background: var(--white);
	color: var(--black);

	overflow-x: hidden;

	&.disabled {
		overflow-y: scroll;
		position: fixed;

		width: 100%;
	}

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		background: var(--light-grey);
		border-radius: 0;
	}

	&::-webkit-scrollbar-thumb {
		background: #3c3c3b;
		border-radius: 0;
	}
}

.swiper-wrapper {
	left: 0 !important;
}

#site {
	position: relative;
	//overflow: hidden; min-height: 100%; display: flex;
	flex-direction: column;
	&:not(.is-visible) {
		overflow: hidden;
	}

	@mixin tab-sm {
		overflow: hidden;
	}
}

.main {
	flex-grow: 1;

	&.snow-bg {
		background: var(--snow);
	}
}

% default-transition {
	transition: ease-in-out 0.25s;
}

.cont {
	margin: 0 auto;
	max-width: 1280px;
	width: perc(1280);

	@mixin tab-md {
		width: 89%;
	}

	@mixin mob-lg {
		width: 91.47%;
	}
}

.full-cont {
	position: relative;
	max-width: 1440px;
	width: 100%;
	margin: 0 auto;
}

section {
	position: relative;
	overflow-x: hidden;
}

#bold-credits {
	width: 150px;
	transition: ease-in-out 0.25s;

	&::before,
	&::after {
		display: none;
	}

	&:hover {
		transform: scale(1.1);
	}
}

.sticky:before,
.sticky:after {
	content: "";
	display: table;
}

.blur-up {
	filter: blur(3px);
	transition: filter 0.4s;
}

.blur-up.lazyloaded {
	filter: blur(0);
}

.decor-parallax {
	transition: transform 0.1s ease;
}

#site.cookie-terms-page {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items: stretch;

	.main {
		flex-grow: 1;
		padding: 0;
	}
}

.cookie-terms-section {
	padding: ac(250px, 140px) 0 ac(150px, 50px);
	p {
		padding: 0;
		margin-bottom: 10px;
	}
	ul,
	ol {
		padding-left: 20px;
		li {
			p {
				margin-bottom: 0;
			}
		}
	}

	ul {
		list-style: circle;
	}

	ol {
		list-style: decimal;
	}

	a {
		display: inline;
		text-decoration: underline;
		transition: 0.2s;

		&:hover {
			opacity: 0.7;
		}
	}
}
.single-page-404 {
	min-height: 100vh;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h1,
	p {
		margin-bottom: 15px;
		padding: 0;
	}
	a {
		display: inline-block;
		margin-top: 20px;
	}
}

.splice-section {
	display: none;
	width: 100%;
	@mixin aspect-ratio-block 1440, 127;
	overflow: visible;
	transform: scale(1.05);
	z-index: 1;
	position: relative;

	&:after {
		position: absolute;
		inset: 0;
		content: "";
	}

	&:after {
		transform: scale(1.02);
	}
}


.grecaptcha-badge {
	z-index: 100;

	@mixin mob-xl {
		opacity: 0;
		pointer-events: none;
	}
}

a[title]::after {
	content: "";
	display: none !important;
}

a[title] {
	position: relative;
}

a[title]::after {
	content: "" !important;
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 100% !important;
	background-color: transparent !important;
}