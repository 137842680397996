.benefits-slider {
	background: var(--white);
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);

	&__item {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		padding: ac(40px, 20px) ac(32px, 20px);
		height: auto;

		&:before {
			content: "";
			top: 50%;
			right: -12px;
			transform: translateY(-50%);
			width: 2px;
			height: ac(80px, 48px);
			background: var(--blue);
			position: absolute;
		}
	}

	&__item-icon {
		@mixin aspect-ratio 1, 1;
		width: ac(60px, 54px);
		margin-bottom: ac(16px, 12px);

		img {
			object-fit: contain;
		}
	}

	&__item-text {
		width: 100%;
		max-height: calc(ac(36px, 26px) * 3);
		text-align: center;

		.simplebar-track.simplebar-vertical {
			width: 4px;
			background: transparent;
			border-radius: 2px;
			transform: translateX(6px);

			.simplebar-scrollbar {
				background: var(--blue);
				border-radius: 2px;

				&:before {
					content: none;
				}
			}
		}
	}
}

.benefits-slider-buttons {
	justify-content: center;
	.slider-btn {
		margin-top: 24px;

		&.swiper-button-lock {
			display: none;
		}
	}
}
