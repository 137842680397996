.our-team-popup {
	padding: 0;
	max-width: 932px;
	width: 100%;
	scrollbar-width: thin;
	scrollbar-color: var(--blue) var(--light-grey);
	max-height: 92vh;
	overflow: hidden;
	background: transparent;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: var(--light-grey);
		border-radius: 0;
	}

	&::-webkit-scrollbar-thumb {
		background: var(--blue);
		border-radius: 0;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: var(--blue);
	}

	.popup__title {
		font-family: var(--font-main);
		font-size: ac(24px, 20px);
		font-weight: 500;
		line-height: 183.33%;
		color: var(--black);
		padding-right: 84px;
		padding-bottom: ac(24px, 8px);
		position: absolute;
		top: 0;
		left: 46.35%;
		height: ac(92px, 60px);
		width: calc(54.65% - 30px);
		background: var(--white);
		text-transform: none;
		@mixin max-line-length-one;
		z-index: 2;
		display: flex;
		align-items: center;
		padding-top: 16px;

		@mixin media 1400 {
			left: 45.35%;
		}

		@mixin tab-sm {
			left: 30px;
			color: var(--white);
			pointer-events: none;
			width: calc(100% - 60px);
		}
	}

	.popup__btn-close {
		top: ac(16px, 8px);
		right: ac(36px, 30px);
		z-index: 10;
		width: ac(48px, 34px);
		height: ac(48px, 34px);
		border-radius: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid var(--black);
		transition: background-color 0.25s ease, border-color 0.25s ease;

		&:before {
			font-family: "icomoon" !important;
			speak: never;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "\e912";
			font-size: ac(48px, 34px);
			color: var(--black);
			transition: color 0.25s ease;
		}

		.cross-clip {
			display: none;
		}

		&:hover {
			background-color: var(--blue);
		}
	}

	.popup__container {
		position: relative;
	}

	.popup__content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		&:before {
			position: absolute;
			content: "";
			bottom: 0;
			height: ac(56px, 40px);
			left: 45.35%;
			width: calc(54.65% - 20px);
			background: var(--white);
			z-index: 10;

			@mixin tab-sm {
				left: 30px;
				width: calc(100% - 60px);
			}
		}
	}

	.our-team-popup {
		&__card {
			width: 41.2%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: ac(48px, 32px) ac(32px, 20px);
			background: var(--white);
			max-height: 66vh;

			@mixin tab-sm {
				display: none;
			}
		}

		&__card-photo {
			@mixin aspect-ratio 1, 1;
			width: calc(100% - ac(80px, 60px));
			margin-bottom: ac(32px, 18px);
		}

		&__card-name {
			font-size: ac(32px, 22px);
			font-weight: 500;
			line-height: ac(48px, 28px);
			text-align: center;

			&:not(:last-child) {
				margin-bottom: ac(8px, 6px);
			}

			@mixin tab-sm {
				text-align: left;
			}
		}

		&__card-position {
			font-size: ac(20px, 16px);
			font-weight: 500;
			line-height: ac(36px, 22px);
			color: var(--dark-grey);
			text-align: center;

			&:not(:last-child) {
				margin-bottom: ac(32px, 18px);
			}

			@mixin tab-sm {
				text-align: left;
			}
		}

		&__card-socials {
			display: flex;
			justify-content: center;
			align-items: center;

			.social-btn {
				margin: 0 ac(6px, 4px);
			}

			&.with-mob {
				justify-content: flex-start;
				margin-bottom: 20px;

				.social-btn {
					&:first-child {
						margin-left: 0;
					}
				}
			}
		}

		&__content {
			width: 58.8%;
			padding: ac(92px, 60px) ac(40px, 30px) ac(56px, 40px);
			/*scrollbar-width: thin;
			scrollbar-color: var(--blue) var(--light-grey);*/
			max-height: 66vh;
			/*overflow-y: auto;*/
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			position: relative;
			border-left: 8px solid var(--blue);
			background: var(--white);
			.simplebar-track.simplebar-vertical {
				width: 20px;
				background: var(--grey);
				border-radius: 0;

				.simplebar-scrollbar {
					background: var(--blue);
					border-radius: 0;
					left: 4px;
					right: 4px;
					box-shadow: inset 0 4px 0 0 var(--grey), inset 0 -4px 0 0 var(--grey);

					&:before {
						content: none;
					}
				}
			}

			@mixin tab-sm {
				width: 100%;
				padding-left: 24px;
				max-height: 88vh;
			}

			/*&::-webkit-scrollbar {
				width: 20px;
			}

			&::-webkit-scrollbar-track {
				background: var(--grey);
				border-radius: 0;
			}

			&::-webkit-scrollbar-thumb {
				background: var(--blue);
				border-radius: 0;
				box-shadow: inset 4px 0 0 0 var(--grey), inset -4px 0 0 0 var(--grey);
			}*/
		}

		&__item {
			max-width: 415px;
			&:not(:last-child) {
				margin-bottom: ac(24px, 20px);
			}

			&.with-mob {
				display: none;
			}

			@mixin tab-sm {
				max-width: none;

				&.with-mob {
					display: block;
				}
			}
		}

		&__title {
			font-size: ac(18px, 17px);
			font-weight: 700;
			line-height: ac(22px, 20px);

			&:not(:last-child) {
				margin-bottom: ac(12px, 10px);
			}
		}

		&__sectors {
			&:not(:last-child) {
				margin-bottom: ac(24px, 20px);
			}

			ol {
				padding-top: ac(8px, 4px);
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				flex-wrap: wrap;
				margin-bottom: -12px;

				li {
					font-weight: 400;
					font-size: ac(14px, 12px);
					line-height: ac(28px, 24px);
					padding-left: 11px;
					padding-right: 11px;
					padding-top: 3px;
					padding-bottom: 3px;
					border: 1px solid var(--black);
					border-radius: 60px;
					margin-right: 8px;
					margin-bottom: 12px;
				}
			}
		}

		&__buttons {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-wrap: wrap;

			.btn {
				margin-right: ac(16px, 10px);
				margin-bottom: ac(16px, 10px);
				padding: 6px ac(31px, 20px);
				min-height: 48px;
				display: flex;
				justify-content: center;
				align-items: center;

				&.btn-border {
					padding-left: ac(28px, 20px);
					padding-right: ac(28px, 20px);
				}

				@mixin mob-lg {
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}

		&__title-mob {
			font-family: var(--font-main);
			font-size: ac(24px, 20px);
			font-weight: 500;
			line-height: 183.33%;
			color: var(--black);
		}
	}

	@mixin tab {
		width: 95%;
	}

	@mixin tab-sm {
	}

	@mixin mob {
	}
}

#popup.active {
	.our-team-popup__card {
		animation-duration: 0.5s;
		animation-name: transformCard;

		@mixin tab-sm {
			animation: none;
		}
	}
}

@keyframes transformCard {
	0% {
		transform: translateX(100%);
		opacity: 0.5;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}
