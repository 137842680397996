.expertise-section {
	padding-top: ac(260px, 128px);
	padding-bottom: ac(104px, 64px);
	background: var(--black);
	overflow: hidden;

	.cont {
		position: relative;
	}

	.line-title {
		position: absolute;
		top: -100px;
		left: 0;
		z-index: 1;
		text-align: end;
		justify-content: flex-end;

		h2 {
			transform: translateX(-22%);
		}

		@mixin tab-md {
			text-align: center;
			justify-content: center;

			h2 {
				transform: translateX(0);
			}
		}
	}

	&__wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 2;

		@mixin tab-md {
			flex-direction: column-reverse;
			align-items: flex-start;
		}
	}

	&__slider {
		width: 55.94%;
		padding: ac(48px, 24px) ac(48px, 24px) ac(56px, 32px);
		background: var(--blue);

		@mixin tab-md {
			width: 100%;
		}
	}

	&__content {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		width: 40.31%;

		.content-element {
			margin-bottom: ac(38px, 24px);
		}

		@mixin tab-md {
			padding-top: 24px;
			width: 100%;
			margin-bottom: ac(32px, 28px);
		}
	}
}
