/* ----------------------- Common desktop header ----------------------- */
.header {
	position: absolute;
	transition: all 0.25s ease;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	padding: ac(40px, 12px) 0;
	display: flex;
	align-items: center;

	&:before {
		content: "";
		background: var(--black);
		inset: 0;
		z-index: -1;
		opacity: 0;
		position: absolute;
		transition: opacity 0.25s ease;
	}

	&.absolute {
		position: absolute;
	}

	&.fixed {
		position: fixed;
		background: transparent;
		backdrop-filter: blur(0px);
	}

	&.scrolled {
		background: rgba(var(--black-rgb), 0.8);
		backdrop-filter: blur(10px);
		padding: ac(20px, 10px) 0;

		&:before {
			opacity: 0.5;
		}

		.logo {
			width: ac(154px, 127px);
		}
	}

	.logo {
		height: 100%;
		width: ac(162px, 127px);
		flex-shrink: 0;
		transition: transform 0.25s ease;
		position: relative;
		z-index: 10000;

		&::before {
			display: none;
		}

		&:hover {
			transform: scale(1.04);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}

		object {
			pointer-events: none;
		}
	}

	.navbar {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.burger {
		display: none;
	}

	.navbar-nav {
		display: flex;
		flex-grow: 1;
	}

	.menu {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}

	.menu-item {
		position: relative;

		&.dropdown {
			& > .menu-link {
				display: inline-flex;
				justify-content: space-between;
				align-items: center;

				&::after {
					border: none;
					border-bottom: 1px solid var(--white);
					border-right: 1px solid var(--white);
					content: "";
					display: block;
					height: 5px;
					pointer-events: none;
					position: absolute;
					right: -14px;
					top: 40%;
					transform-origin: 66% 66%;
					transform: rotate(45deg) scale(1.5);
					transition: all 0.15s ease-in-out;
					width: 5px;
				}
			}

			&:hover {
				& > .menu-link {
					&:after {
						transform: rotate(-135deg) scale(1.5);
					}
				}
			}

			&.active {
				& > .menu-link {
					&:after {
						transform: rotate(-135deg) scale(1.5);
					}
				}
			}
		}

		&:not(:last-child) {
			margin-right: ac(40px, 16px);
		}
	}

	&:not(.header-mobile) {
		.menu-item {
			@media (--mobile-menu-end-point) {
				padding: 3px 0;
			}

			&:hover {
				@media (--mobile-menu-end-point) {
					.dropdown-menu {
						display: block;
					}
				}
			}
		}
	}

	.menu-link {
		position: relative;
		font-size: ac(16px, 14px);
		@mixin transition-all;
		color: var(--white);
		font-weight: 600;

		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;
			width: 0;
			height: 2px;
			background: var(--white);
			@mixin transition-all;
		}

		&:hover {
			color: var(--blue);

			&::before {
				width: 100%;
				left: 0;
				background: var(--blue);
			}
		}
	}

	.dropdown-menu {
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		min-width: max-content;
		padding: 10px 12px;
		border: 2px solid var(--blue);
		border-radius: 0 0 0 16px;
		background: var(--black);
		box-sizing: content-box;
		display: none;
		z-index: 100;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		li {
			.menu-link {
				padding: 2px 0;
				text-transform: capitalize;
			}
		}
	}

	.menu-bottom {
		display: none;
	}
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@media (--mobile-menu-start-point) {
	.header:not(.header-mobile) {
		.burger,
		.menu-toggle {
			display: flex;
		}

		.navbar-nav {
			position: fixed;
			right: 0;
			top: 0;
			width: 60%;
			height: auto;
			min-width: 320px;
			max-height: 100vh;
			padding-top: 180px;
			background: var(--black);
			padding-right: 40px;
			padding-bottom: 80px;
			transition: opacity 0.25s ease-out, clip-path 0.5s ease,
				background-color 0.4s ease 0.1s, border-radius 0.3s ease;
			display: flex;
			flex-direction: column;
			align-items: stretch;
			justify-content: center;
			clip-path: circle(25px at calc(100% - 45px) 45px);
			pointer-events: none;
			opacity: 0;
			background: var(--white);

			@mixin tab-sm {
				height: 100vh;
				height: calc(var(--vh, 1vh) * 100);
				max-height: none;
				padding-top: 160px;
				padding-bottom: 40px;
				justify-content: space-between;
			}

			&.active {
				pointer-events: auto;
				clip-path: circle(75%);
				opacity: 1;
				background: var(--black);
				border-radius: 60px 0 0 60px;
				@mixin tab-sm {
					border-radius: 0;
				}
			}

			&.nav-slide-left {
				right: auto;
				left: 0;
				transform: translateX(-100%);

				&.active {
					transform: translateX(0);
				}
			}

			&.nav-slide-right {
				right: 0;
				left: auto;
				transform: translateX(100%);

				&.active {
					transform: translateX(0);
				}
			}

			&.nav-slide-down {
				right: auto;
				left: 0;
				transform: translateY(-100%);

				&.active {
					transform: translateY(0);
				}
			}

			&.nav-slide-up {
				right: auto;
				left: 0;
				transform: translateY(100%);

				&.active {
					transform: translateY(0);
				}
			}

			.menu {
				width: 100%;
				max-height: 100%;
				overflow-y: auto;
				flex-direction: column;
				justify-content: flex-start;
				padding-left: 40px;
				align-items: flex-start;
				align-self: center;

				&::-webkit-scrollbar {
					width: 3px;
					height: 2px;
					background: rgba(255, 255, 255, 0.1);
					border-radius: 3px;
				}

				&::-webkit-scrollbar-thumb {
					background: rgba(255, 255, 255, 0.5);
					border-radius: 3px;
					cursor: pointer;
				}

				@mixin mob {
					padding-left: 16px;
				}
			}

			.menu-link {
				padding: 0;
				font-size: ac(36px, 24px);
				line-height: ac(40px, 28px);
			}

			.menu-item {
				&:not(:last-child) {
					margin-bottom: 28px;
				}

				&.dropdown.active {
					&:not(:last-child) {
						margin-bottom: 12px;
					}
					.menu-link {
						&:not(:last-child) {
							margin-bottom: 12px;
						}
					}
				}
			}

			.menu-bottom {
				margin-top: 64px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				border-top: 1px solid var(--dark-grey);
				padding-top: 24px;
				padding-bottom: 24px;
				margin-left: 40px;

				@mixin mob-xl {
					margin-top: 24px;
				}

				@mixin mob {
					margin-left: 16px;
				}

				&__link {
					color: var(--blue);
					font-family: var(--font-main);
					font-weight: 400;
					font-size: ac(24px, 16px);
					line-height: 19px;
					transition: color 0.25s ease;

					&:not(:last-child) {
						margin-bottom: 12px;
					}

					&:hover {
						color: var(--white);
					}
				}
			}

			@mixin mob {
				padding-right: 16px;
			}

			@mixin tab-sm {
				width: 100%;
			}
		}

		.dropdown-menu {
			position: relative;
			top: 0;
			border: none;
			padding: 0;
			padding-left: 20px;

		}
	}
}

/* end of Common desktop header Mobile HEADER */

/* ----------------------- ONLY Mobile HEADER ----------------------- */
.header.header-mobile {
	.burger {
		display: flex;
	}

	.navbar-nav {
		position: fixed;
		right: 0;
		top: 0;
		width: 50%;
		height: 100vh;
		min-width: 320px;
		max-height: 100vh;
		padding-top: 100px;
		background: var(--bg-second);
		padding-right: 5px;
		padding-bottom: 40px;
		transition: transform 0.3s ease-out;

		&.nav-slide-left {
			right: auto;
			left: 0;
			transform: translateX(-100%);

			&.active {
				transform: translateX(0);
			}
		}

		&.nav-slide-right {
			right: 0;
			left: auto;
			transform: translateX(100%);

			&.active {
				transform: translateX(0);
			}
		}

		&.nav-slide-down {
			right: auto;
			left: 0;
			transform: translateY(-100%);

			&.active {
				transform: translateY(0);
			}
		}

		&.nav-slide-up {
			right: auto;
			left: 0;
			transform: translateY(100%);

			&.active {
				transform: translateY(0);
			}
		}

		.menu {
			width: 100%;
			max-height: 100%;
			overflow-y: auto;
			flex-direction: column;
			justify-content: flex-start;
			padding-left: 40px;
			align-items: flex-start;
			align-self: center;

			&::-webkit-scrollbar {
				width: 3px;
				height: 2px;
				background: rgba(255, 255, 255, 0.1);
				border-radius: 3px;
			}

			&::-webkit-scrollbar-thumb {
				background: rgba(255, 255, 255, 0.5);
				border-radius: 3px;
				cursor: pointer;
			}
		}

		.menu-link {
			padding: 10px 0;
			font-size: ac(25px, 18px);
		}
	}

	.dropdown-menu {
		position: relative;
		top: 0;
	}
}

/* end of ONLY Mobile HEADER */

/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger,
.menu-toggle {
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: 56px;
	height: 56px;
	padding-left: 13px;
	padding-right: 13px;
	display: flex;
	background: var(--white);
	transition: all 0.01s linear;

	span {
		width: 16px;
		height: 2px;
		background: var(--black);
		transition: transform 0.15s ease, background-color 0.15s ease;
		position: relative;
		margin-left: 4px;

		i {
			top: 50%;
			left: -12px;
			transform: translateY(-50%);
			position: absolute;
			color: var(--black);
			font-size: 15px;
		}

		&::before,
		&::after {
			position: absolute;
			content: "";
			right: 0;
			width: 20px;
			height: 2px;
			background: var(--black);
			transition: all 0.15s;
		}

		&::before {
			top: -8px;
		}

		&::after {
			bottom: -8px;
		}
	}

	@mixin transition-all;

	@mixin min-media 1025 {
		&:hover {
			/*transform: scale(1.1);*/
			&:not(.active) {
				span {
					&:before {
						width: 16px;
						margin-left: -4px;
						right: auto;
					}

					&:after {
						width: 16px;
						margin-right: -2px;
					}

					i {
						transform: translateY(-50%) translateX(-2px);
					}
				}
			}
		}
	}

	&.active {
		span {
			background: transparent;

			i {
				transform: scale(0) translateY(-50%);
				opacity: 0;
			}

			&::before {
				top: 0;
				transform: rotate(-45deg);
			}

			&::after {
				top: 0;
				transform: rotate(45deg);
			}
		}

		&:hover {
		}
	}
}

.header-close-wrapper {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 77;
	background: rgba(0, 0, 0, 0.5);
	pointer-events: none;
	visibility: hidden;
	opacity: 0;

	@mixin transition-all;

	&.active {
		visibility: visible;
		pointer-events: auto;
		opacity: 1;
		cursor: pointer;
	}
}

/* end of HEADER COMPONENTS */
