/*
Styles for popup background and basic styles for fonts
*/
.termsfeed-com---palette-dark.termsfeed-com---nb {
    background: var(--black) !important;
    color: var(--white);
    font-family: var(--font-main);
    border-radius: 20px 0 0 0;

    .cc-nb-okagree, .cc-nb-reject, .cc-nb-changep {
        margin-right: 13px !important;
        margin-bottom: 20px !important;
        margin-top: 13px !important;
        text-transform: capitalize;
    }

    .cc-nb-title, .cc-nb-text {
        color: var(--white);
    }

    #cc-nb-text{
        font-weight: 400;
    }
    #cc-nb-title{
        color: var(--white);
        font-family: var(--font-main);
    }
}

.termsfeed-com---nb {
    /*
    Styles for buttons
    */
    .cc-nb-okagree,
    .cc-nb-reject,
    .cc-nb-changep,
    .cc-cp-foot-button .cc-cp-foot-save {
        background: var(--white);
        padding: 9px 38px;
        position: relative;
        overflow: hidden;
        border: 2px solid var(--white);
        transition: border-color 0.25s ease, transform 0.25s ease, background-color .15s ease;
        cursor: pointer;
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: fit-content;
        font-size: 18px;
        font-weight: 700;
        color: var(--black);
        z-index: 2;

        &:focus {
            box-shadow: none !important;
            border: 2px solid var(--white) !important;
        }

        &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: var(--blue);
            opacity: 0;
            border-radius: 100px;
            transition: all 0.3s ease;
            z-index: -1;
            transform: scale(0) rotate(-12deg);
        }

        &:hover {
            background: transparent;

            &:before {
                transform: scale(1) rotate(0deg);
                opacity: 1;
                border-radius: 0;
            }
        }
    }

    .cc-nb-buttons-container{
        display:flex!important;
        flex-wrap: wrap;
    }
}

/*
Styles for popup with settings
*/
.termsfeed-com---palette-dark {
    .cc-pc-container {
        font-family: var(--font-main)!important;
    }

    .cc-pc-head, .cc-cp-body-content, .cc-cp-foot {
        background: var(--black) !important;
        border-top-color: var(--blue) !important;
        border-bottom-color: var(--blue) !important;
    }

    .cc-cp-body-tabs {
        font-family: var(--font-main)!important;
    }

    .cc-cp-body-tabs {
        background-color: var(--snow);
    }

    .cc-cp-body-tabs-item {
        background: var(--snow);
    }

    .cc-cp-body-tabs-item-link {
        color: var(--black);

        &:focus {
            box-shadow: 0 0 0 2px #fff
        }
    }

    .cc-cp-body-tabs-item[active=true] {
        background: var(--blue) !important;

        .cc-cp-body-tabs-item-link {
            color: var(--black);
        }
    }

    .cc-pc-head-lang {
        select {
            color: var(--black);
            padding: 5px;
            background: var(--snow);
            cursor: pointer;

            &:focus {
                box-shadow: none;
            }

            option {
                font-size: ac(18px, 16px);
                font-weight: 400;
                line-height: ac(32px, 28px);
                font-style: normal;
                font-family: var(--font-main);
                cursor: pointer;
            }
        }
    }

    .cc-pc-head-close {
        color: var(--blue);
        transition: all .3s ease;

        &:hover {
            color: var(--snow);
            transform: scale(1.1);
        }

        &:focus {
            border: 0;
        }
    }

}

/*
Styles for the lower part of the popup with settings
*/
.termsfeed-com---pc-dialog {
    .cc-cp-body-tabs {
        font-family: var(--font-main)!important;
    }

    .cc-cp-foot {
        padding: 10px 0;
        flex-wrap: wrap;
    }

    .cc-cp-foot-byline {
        a {
            color: var(--blue);
            text-decoration: underline;
            transition: all .3s ease;

            &:hover {
                color: var(--grey);
            }
        }
    }

    .cc-cp-foot-button .cc-cp-foot-save {
        background: var(--white);
        padding: 9px 38px;
        position: relative;
        overflow: hidden;
        border: 2px solid var(--white);
        transition: border-color 0.25s ease, transform 0.25s ease, background-color .15s ease;
        cursor: pointer;
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: fit-content;
        font-size: 18px;
        font-weight: 700;
        color: var(--black);
        z-index: 2;
        margin-left: 10px;

        &:focus {
            box-shadow: none !important;
            border: 2px solid var(--white) !important;
        }

        &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: var(--blue);
            opacity: 0;
            border-radius: 100px;
            transition: all 0.3s ease;
            z-index: -1;
            transform: scale(0) rotate(-12deg);
        }

        &:hover {
            background: transparent;

            &:before {
                transform: scale(1) rotate(0deg);
                opacity: 1;
                border-radius: 0;
            }
        }

        @mixin mob-lg {
            margin-bottom: 10px;
        }
    }
}

/*
Styles for mobile adaptation
*/

@mixin mob-lg {
    .termsfeed-com---nb-simple {
        max-width: 90%;
    }

    .termsfeed-com---nb .cc-nb-okagree, .termsfeed-com---nb .cc-nb-reject, .termsfeed-com---nb .cc-nb-changep {
        margin-top: 0 !important;
        max-width: 210px;
    }

    .termsfeed-com---nb .cc-nb-main-container {
        padding: 2rem
    }
}

@mixin mob {
    .termsfeed-com---nb .cc-nb-okagree, .termsfeed-com---nb .cc-nb-reject, .termsfeed-com---nb .cc-nb-changep {
        min-width: 100%;
        text-align: center !important;
        justify-content: center;
        font-size: 16px;

        @mixin media 340 {
            font-size: 14px;
        }
    }
}

@mixin media 380 {
    .termsfeed-com---nb-simple {
        max-width: 100%;
    }
}

@media (max-width: 320px), (max-height: 480px) {
    .termsfeed-com---nb-simple {
        height: auto;
    }
}