.expertise-slider {
	width: 100%;
	max-width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	overflow: visible;
	clip-path: inset(0 -10px 0 0);

	&__item-title {
		margin-bottom: 20px;
	}

	&__item-text {
		width: 100%;
		max-height: calc(ac(32px, 28px) * 6);

		.simplebar-track.simplebar-vertical {
			width: 4px;
			background: transparent;
			border-radius: 2px;
			transform: translateX(8px);

			.simplebar-scrollbar {
				background: var(--dark-grey);
				border-radius: 2px;

				&:before {
					content: none;
				}
			}
		}
	}
}

.expertise-slider-buttons {
	margin-left: 0;
	.slider-btn {
		margin-top: ac(72px, 32px);
	}
}
