.client-logo-card {
	border-left: 1.5px solid var(--grey);
	border-right: 1.5px solid var(--grey);
	border-top: 8px solid var(--grey);
	border-bottom: 8px solid var(--grey);
	background: var(--white);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: ac(44.5px, 32px);
	transition: border-color 0.3s ease;
	cursor: default;

	&__icon {
		width: 100%;
		@mixin aspect-ratio 204, 58;

		img {
			object-fit: contain;
			transition: filter 0.3s ease;
			filter: contrast(0%);
		}
	}

	&:hover {
		@mixin min-media 652 {
			border-color: var(--blue);
			.client-logo-card__icon img {
				filter: contrast(100%);
			}
		}
	}

	&.swiper-slide-active {
		border-color: var(--blue);
		.client-logo-card__icon img {
			filter: contrast(100%);
		}
	}
}
