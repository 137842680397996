.sectors-section {
	background: var(--snow);
	overflow: hidden;
	z-index: 4;
	padding-top: ac(260px, 146px);
	padding-bottom: ac(138px, 58px);

	.line-title {
		position: absolute;
		top: ac(154px, 74px);
		left: 0;
		z-index: 1;
	}

	&__decor {
		@mixin aspect-ratio 991, 1076;
		width: ac(991px, 498px);
		position: absolute;
		top: 0;
		left: 0;
		transform: translateX(ac(-100px, -197px)) translateY(ac(-138px, -40px));
		overflow: visible;
		mask-image: none;
		img {
			object-fit: contain;

			&.blur-up {
				transition: filter 0.4s, transform 0.1s ease;
			}
		}
	}

	.sectors-slider {
		width: 100%;
		position: relative;
		z-index: 2;
	}
}

.our-sectors-section {
	padding-top: ac(300px, 160px);
	padding-bottom: ac(80px, 40px);
	overflow: hidden;
	background: var(--black);

	.cont {
		position: relative;
	}

	.line-title {
		position: absolute;
		top: ac(-216px, -130px);
		left: 0;
		z-index: 1;
		text-align: left;
		justify-content: left;
		background: var(--snow);
		padding-left: ac(124px, 0px);

		&__bg {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%) translateY(-99%);
			height: 300px;
			background: var(--snow);
			width: 200%;
		}
	}

	+ .splice-section {
		display: block;
		background: var(--black);

		&:after {
			background: var(--snow);
			clip-path: polygon(0 0, 0% 100%, 100% 100%);
		}

		+ .latest-jobs-section {
			margin-top: ac(24px, 0px);
		}
	}
}
