.contact-input input {
	font-family: var(--font-main);
	font-size: ac(22px, 16px);
	font-weight: 400;
	line-height: ac(34px, 20px);
	color: var(--white);
	border-radius: 0;
	border-bottom: 1px solid transparent;
	background: transparent;
	@mixin transparent-bg-input var(--white);
	transition: border-color 0.25s ease, caret-color 0.25s ease;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	@mixin transparent-bg-input var(--white);

	&::placeholder {
		color: var(--grey);

		@mixin mob-xl {
			color: color-mod(var(--light-grey) a(60%));
		}
	}

	&:focus {
		border-color: var(--blue);
		caret-color: var(--blue);
	}
}

.checkboxes-contact {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;

	.checkbox-contact-btn {
		margin-right: ac(5px, 4px);
		margin-bottom: ac(5px, 8px);
	}
}

.checkbox-contact-btn {
	position: relative;
	user-select: none;
	cursor: pointer;
	display: flex;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	span {
		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: ac(19px, 11px);
		padding-right: ac(19px, 11px);
		border: 1px solid var(--black);
		font-family: var(--font-main);
		font-size: ac(16px, 16px);
		font-weight: 500;
		line-height: 1.25;
		color: var(--black);
		background: transparent;
		transition: all 0.25s ease;
		border-radius: ac(55px, 60px);

		@mixin mob {
			line-height: 1.15;
		}
	}

	input:checked ~ span {
		background: var(--black);
		color: var(--white);
	}

	&:hover {
		span {
			border-color: color-mod(var(--black) a(20%));
			background: color-mod(var(--black) a(10%));
			color: var(--blue);
		}
	}
}

.default-input {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	label {
		font-family: var(--font-main);
		font-size: 14px;
		line-height: 28px;
		font-weight: 500;
		color: var(--black);
		padding-bottom: 8px;
		padding-left: ac(16px, 12px);
	}

	input,
	textarea {
		width: 100%;
		font-size: 14px;
		line-height: 28px;
		font-weight: 500;
		font-family: var(--font-main);
		color: var(--black);
		border-width: 2px 2px 4px 2px;
		border-style: solid;
		border-color: var(--grey);
		transition: all 0.3s ease;
		padding-left: ac(16px, 12px);
		padding-right: ac(16px, 12px);
		min-height: ac(56px, 48px);
		resize: none;
		background: transparent;
		@mixin transparent-bg-input var(--black);

		&::placeholder {
			color: var(--dark-grey);
		}

		&:focus {
			border-color: var(--blue);
			caret-color: var(--dark-grey);
		}
	}

	textarea {
		padding-top: ac(12px, 10px);
		flex: 1;
	}

	&.dark-bg {
		label {
			color: var(--white);
		}

		input,
		textarea {
			color: var(--white);
			border-color: var(--white);
			background: transparent;
			@mixin transparent-bg-input var(--white);

			&::placeholder {
				color: var(--grey);
			}

			&:focus {
				border-color: var(--blue);
				caret-color: var(--blue);
			}
		}
	}
}

.default-select {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	label {
		font-family: var(--font-main);
		font-size: 14px;
		line-height: 28px;
		font-weight: 500;
		color: var(--black);
		padding-bottom: 8px;
		padding-left: ac(16px, 12px);
	}

	select {
		opacity: 0;
	}

	.choices {
		width: 100%;
		font-size: 14px;
		line-height: 28px;
		font-weight: 500;
		font-family: var(--font-main);
		color: var(--black);

		&__inner {
			border-width: 2px 2px 4px 2px;
			border-style: solid;
			border-color: var(--grey);
			background: transparent;
			padding-left: ac(16px, 12px);
			padding-right: 20px;
			min-height: ac(56px, 48px);
			display: flex;
			align-items: center;
			justify-content: flex-start;
			@mixin max-line-length-one;
		}

		&__item {
			@mixin max-line-length-one;
		}

		&__placeholder {
			color: var(--black);
			opacity: 1;
		}

		&__list {
			padding: 0;

			.choices__item {
				padding-right: ac(16px, 12px);
			}
		}

		&[data-type*="select-one"] {
			&:after {
				border: none;
				border-bottom: 1px solid var(--black);
				border-right: 1px solid var(--black);
				content: "";
				display: block;
				height: 5px;
				margin-top: -4px;
				pointer-events: none;
				position: absolute;
				right: 16px;
				top: 50%;
				transform-origin: 66% 66%;
				transform: rotate(45deg) scale(1.5);
				transition: all 0.15s ease-in-out;
				width: 5px;
			}
		}

		&.is-open {
			&:after {
				transform: rotate(-135deg) scale(1.5);
			}
		}

		&__list--dropdown,
		.choices__list[aria-expanded] {
			border: 2px solid var(--grey);
			z-index: 20;
		}
	}

	.choices__list--dropdown .choices__list, .choices__list[aria-expanded] .choices__list {
		max-height: 220px;

		&::-webkit-scrollbar {
			width: 3px;
		}

		&::-webkit-scrollbar-track {
			background: var(--light-grey);
			border-radius: 0;
		}

		&::-webkit-scrollbar-thumb {
			background: var(--dark-grey);
			border-radius: 0;
		}
	}

	.choices[data-type*="select-one"] .choices__input {
		margin: 2px;
		max-width: calc(100% - 4px);
		border-radius: 2px;
		border: 2px solid var(--grey);
		color: var(--black);
	}

	&.dark-bg {
		.choices {
			color: var(--white);
			&__inner {
				border-color: var(--white);
			}

			&__placeholder {
				color: var(--white);
			}

			&[data-type*="select-one"] {
				&:after {
					border-color: var(--white);
				}
			}
		}

		.choices__list--dropdown {
			.choices__item {
				color: var(--black);
			}
		}
	}
}

.default-popup-file-input {
	width: 100%;
	position: relative;
	border-width: 2px 2px 4px 2px;
	border-style: solid;
	border-color: var(--grey);
	background: transparent;
	padding-left: ac(16px, 12px);
	padding-right: 20px;
	min-height: ac(56px, 48px);
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
	transition: border-color 0.3s ease;
	padding-bottom: 0;

	.label-text {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		text-align: center;

		font-family: var(--font-main);
		font-size: 14px;
		line-height: 28px;
		font-weight: 500;
		color: var(--black);

		svg {
			width: 18px;
			height: 18px;
			margin-right: 5px;
		}

		span {
			@mixin max-line-length-one;
		}
	}

	input {
		position: absolute;
		opacity: 0;
		inset: 0;
		cursor: pointer;
		width: 100%;
	}

	&:hover {
		border-color: var(--blue);
	}
}

.default-file-upload {
	position: relative;
	@mixin aspect-ratio-block 436, 260;
	overflow: visible;
	border: 2px dashed var(--grey);
	cursor: pointer;
	transition: all 0.3s ease;

	&:after {
		content: "";
		position: absolute;
		bottom: -2px;
		left: -2px;
		right: -2px;
		height: 0;
		background: var(--blue);
		opacity: 0;
		transition: all 0.25s ease;
		z-index: 3;
		pointer-events: none;
	}

	input {
		position: absolute;
		inset: 0;
		opacity: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
		z-index: 2;
	}

	&__content {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 10px;
		position: absolute;
		inset: 0;
		z-index: 1;
	}

	&__icon {
		width: ac(60px, 44px);
		@mixin aspect-ratio 60, 48;
		margin-bottom: 8px;

		img {
			object-fit: contain;
		}
	}

	&__text {
		margin-bottom: ac(8px, 6px);
		text-align: center;
	}

	&__subtext {
		font-family: var(--font-main);
		color: var(--dark-grey);
		font-size: ac(14px, 12px);
		line-height: ac(28px, 20px);
		font-weight: 500;
		text-align: center;
	}

	.btn {
		margin-top: ac(24px, 16px);
		border-color: var(--black);
	}

	&__file-content {
		display: none;
		justify-content: center;
		align-items: center;
		padding: 10px;
		position: absolute;
		inset: 0;
		z-index: 3;
	}

	&__file-icon {
		@mixin aspect-ratio 42, 56;
		width: ac(56px, 34px);
		min-width: ac(56px, 34px);
		margin-right: ac(16px, 8px);
	}

	&__file-name {
		display: flex;
		justify-content: center;
		align-items: center;
		color: var(--black);
		font-size: ac(22px, 18px);
		line-height: ac(36px, 26px);
		font-weight: 500;
		max-width: 55%;

		span {
			max-width: 100%;
			@mixin max-line-length-one;
		}
	}

	&__file-btn {
		width: ac(32px, 28px);
		min-width: ac(32px, 28px);
		height: ac(32px, 28px);
		font-size: ac(32px, 28px);
		background: var(--blue);
		border-radius: 50%;
		color: var(--black);
		position: relative;
		margin-left: ac(12px, 6px);

		i {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateY(-50%) translateX(-50%);
			z-index: 1;
			transition: all 0.25s ease;
		}

		.icon-upload-check {
			opacity: 1;
		}

		.icon-upload-close {
			opacity: 0;
		}

		/*&:hover {
			.icon-upload-check {
				opacity: 0;
			}

			.icon-upload-close {
				opacity: 1;
			}
		}*/
	}

	&:hover {
		border-color: var(--blue);
		border-bottom: 2px solid var(--blue);

		.btn {
			border-color: var(--blue);
		}

		&:after {
			height: 8px;
			opacity: 1;
		}
	}

	&.active {
		.default-file-upload__content {
			display: none;
		}

		.default-file-upload__file-content {
			display: flex;
		}
	}

	&.dark-bg {
		.default-file-upload__subtext {
			color: var(--grey);
		}

		.default-file-upload__file-name {
			color: var(--snow);
		}

		.btn {
			border-color: var(--white);
		}

		&:hover {
			border-color: var(--blue);

			.btn {
				border-color: var(--blue);
				transition: all 0.3s ease;
			}
		}
	}
}

.checkbox {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;

	.checkbox-box {
		display: flex;
		justify-content: center;
		align-items: center;
		width: ac(26px, 22px);
		height: ac(26px, 22px);
		border: 2px solid var(--dark-grey);
		border-radius: 1px;
		margin-right: 8px;
		margin-left: 0;
		transition: border-color 0.3s ease;

		i {
			font-size: ac(26px, 22px);
			color: var(--black);
			opacity: 0;
			transition: opacity 0.25s ease, color 0.3s ease;
		}
	}

	input {
		display: none;
	}

	.checkbox-text {
		font-weight: 400;
		font-size: ac(16px, 14px);
		line-height: ac(28px, 24px);
		font-family: var(--font-main);
		color: var(--black);

		a {
			font: inherit;
			text-decoration: underline;
			color: var(--blue);
			transition: all 0.25s ease;

			&:hover {
				text-decoration-color: transparent;
			}
		}
	}

	input:checked ~ .checkbox-box {
		border-color: var(--black);

		i {
			color: var(--black);
			opacity: 1;
		}
	}

	&.dark-bg {
		.checkbox-box {
			border-color: var(--snow);

			i {
				color: var(--snow);
			}
		}

		input:checked ~ .checkbox-box {
			border-color: var(--white);

			i {
				color: var(--blue);
			}
		}

		.checkbox-text {
			color: var(--snow);
		}
	}
}

.double-range-container {
	height: 72px;
}

.double-range {
	padding-right: 15px;
	margin-top: 27px;
	margin-bottom: 9px;
	height: 2px;
	border-radius: 0;
	border: none;
	box-shadow: none;
	background: var(--dark-grey);

	.noUi-connect {
		background: var(--blue);
		margin-right: -4px;
	}

	.noUi-handle {
		background: var(--grey);
		width: 22px;
		height: 22px;
		border: none;
		cursor: pointer;
		border-radius: 0;
		box-shadow: none;
		top: -10px;
		transition: all 0.25s ease;

		&:before,
		&:after {
			content: none;
		}

		&:hover {
			background-color: var(--blue);
		}
	}
}

.double-range-inputs {
	&__container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.double-range-input {
		border-width: 1.5px 1.5px 4px 1.5px;
		border-style: solid;
		border-color: var(--black);
		padding: 1.5px 10px;
		width: 38%;
		height: auto;
		color: var(--black);
		background: transparent;
		text-align: center;
		font-size: 16px;
		line-height: 28px;
		font-family: var(--font-main);
		font-weight: 400;
		transition: border-color 0.25s ease;

		&:focus {
			border-color: var(--blue);
		}

		@mixin tab {
			width: 42%;
		}
	}

	span {
		flex: 1;
		height: 1px;
		margin-left: ac(16px, 14px);
		margin-right: ac(16px, 14px);
		background: var(--black);
		display: flex;
	}
}

.single-range {
	padding-right: 15px;
	margin-bottom: 0;
	height: 2px;
	border-radius: 0;
	border: none;
	box-shadow: none;
	background: var(--dark-grey);

	.noUi-connect {
		background: var(--blue);
		margin-right: 0;
	}

	.noUi-handle {
		background: var(--grey);
		width: 22px;
		height: 22px;
		border: none;
		cursor: pointer;
		border-radius: 0;
		box-shadow: none;
		top: -10px;
		transition: all 0.25s ease;

		&:before,
		&:after {
			content: none;
		}

		&:hover {
			background-color: var(--blue);
		}
	}
}
.single-range-info {
	font-family: var(--font-main);
	font-size: ac(16px, 14px);
	font-weight: 400;
	line-height: normal;
	color: var(--white);
	margin-right: ac(8px, 6px);
	min-width: ac(76px, 68px);
}

.single-range-container {
	display: flex;
	align-items: center;

	.single-range {
		flex: 1;
		order: 2;
	}

	.single-range-info {
		order: 1;
	}
}
