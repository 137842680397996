.history-section {
	padding-top: ac(256px, 130px);
	padding-bottom: ac(260px, 160px);
	background: var(--snow);

	@mixin mob {
		padding-bottom: 120px;
	}

	@mixin media 375 {
		padding-bottom: 110px;
	}

	.cont {
		position: relative;
	}

	.line-title {
		position: absolute;
		top: -90px;
		left: 0;
		z-index: 1;
		text-align: start;
		justify-content: flex-start;
	}

	&__wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@mixin tab-md {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	&__slider {
		width: 55.94%;

		@mixin tab-md {
			width: 100%;

			&:not(:last-child) {
				margin-bottom: ac(32px, 28px);
			}
		}

		@mixin mob-lg {
			width: 95%;
		}
	}

	&__content {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		width: 40.31%;

		.content-element {
			margin-bottom: ac(40px, 24px);
		}

		@mixin tab-md {
			padding-top: 30px;
			width: 100%;

			&:not(:last-child) {
				margin-bottom: 64px;
			}
		}
	}
}
