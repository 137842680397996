h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a {
	font-family: var(--font-main);
	color: var(--black);
}

.dark-bg {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	li,
	a {
		color: var(--white);
	}

	.light-bg {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p,
		li,
		a {
			color: var(--black);
		}
	}
}

h1 {
	font-size: ac(60px, 30px);
	font-weight: 800;
	line-height: ac(72px, 40px);
}

h2 {
	font-size: ac(48px, 28px);
	font-weight: 800;
	line-height: ac(60px, 34px);
}

.big-title {
	h2 {
		font-size: ac(60px, 30px);
		font-weight: 800;
		line-height: ac(72px, 40px);
	}
}

h3 {
	font-size: ac(34px, 24px);
	font-weight: 700;
	line-height: ac(44px, 30px);
}

h4 {
	font-size: ac(32px, 24px);
	font-weight: 500;

	line-height: 130%;

	margin-bottom: ac(20px, 16px);
}

h5 {
	font-size: ac(28px, 20px);
	font-weight: 500;

	line-height: 1.5;

	margin-bottom: ac(18px, 10px);
}

h6 {
	font-size: ac(24px, 18px);
	font-weight: 500;

	line-height: 2;
}

p {
	font-size: ac(18px, 16px);
	font-weight: 400;
	line-height: ac(32px, 28px);
}

li {
	font-size: ac(18px, 16px);
	font-weight: 400;
	line-height: ac(32px, 28px);
}

a {
	font-size: ac(18px, 16px);
	font-weight: 400;
	line-height: ac(32px, 28px);
	display: inline-block;
}

.subtitle {
	h3 {
		font-size: ac(32px, 28px);
		font-weight: 500;
		line-height: ac(44px, 40px);
	}
}

.line-title {
	padding-top: ac(10px, 19px);
	padding-bottom: ac(10px, 19px);
	background: var(--white);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	transform: rotate(5deg);
	z-index: 5;
	outline: 1px solid transparent;

	&:after,
	&:before {
		position: absolute;
		inset: 0;
		content: "";
		background: inherit;
		z-index: -1;
	}

	&:before {
		transform: translateX(-98%);
	}

	&:after {
		transform: translateX(98%);
	}

	& > h1,
	h2,
	h3 {
		color: var(--black);
		text-align: center;
		text-transform: lowercase;
	}

	h3 {
		font-size: ac(34px, 24px);
		font-weight: 700;
		line-height: ac(48px, 30px);
	}

	&.line-title-second {
		transform: rotate(-5deg);
	}

	&.line-title-dark {
		background: var(--black);

		& > * {
			color: var(--white);
		}
	}

	&.line-title-blue {
		background: var(--blue);
	}

	@mixin mob-sm {
		h2 {
			font-size: 22px;
		}
	}
}

.blockquote-content {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	p,
	li {
		font-size: ac(18px, 16px);
		font-weight: 800;
		line-height: ac(32px, 26px);
		font-style: italic;

		a {
			text-decoration: underline;
		}

		@mixin mob-lg {
			font-weight: 600;
		}
	}

	&__icon {
		font-size: ac(19px, 17px);
		&:first-child {
			transform: translateY(-50%);
			margin-right: 8px;

			@mixin mob {
				transform: translateY(-200%);
			}
		}

		&:last-child {
			transform: translateY(50%);
			margin-left: 8px;

			@mixin mob {
				transform: translateY(200%);
			}
		}
	}
}

.label-text {
	font-family: var(--font-main);
	font-size: ac(22px, 16px);
	font-weight: 500;
	line-height: ac(34px, 20px);
	color: var(--white);
}

.content-element {
	h1 {
		font: 800 ac(60px, 30px) / ac(72px, 40px) var(--font-main) !important;
		&:not(:last-child) {
			margin-bottom: ac(28px, 20px);
		}
	}

	h2 {
		font: 800 ac(48px, 28px) / ac(60px, 34px) var(--font-main) !important;
		&:not(:last-child) {
			margin-bottom: ac(24px, 18px);
		}
	}

	h3 {
		font: 700 ac(34px, 24px) / ac(44px, 30px) var(--font-main) !important;
		&:not(:last-child) {
			margin-bottom: ac(20px, 16px);
		}
	}

	p,
	li {
		font: 400 ac(18px, 16px) / ac(32px, 28px) var(--font-main) !important;

		&:not(:last-child) {
			margin-bottom: ac(16px, 14px);
		}

		ul,
		ol {
			padding-top: 10px;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	li {
		a {
			font: inherit !important;
			color: var(--dark-grey);
			text-decoration: underline;
			text-decoration-color: var(--dark-grey);
			transition: all 0.25s ease;
			text-decoration-thickness: 2px;
			text-decoration-skip-ink: none;
			word-wrap: break-word;
			overflow-wrap: break-word;

			&:hover {
				color: var(--blue);
				text-decoration-color: var(--blue);
			}
		}

		span {
			font: inherit !important;

			span {
				font: inherit !important;

				span {
					font: inherit !important;

					span {
						font: inherit !important;

						span {
							font: inherit !important;

							span {
								font: inherit !important;

								span {
									font: inherit !important;

									span {
										font: inherit !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	&:not(.checked-list-type) {
		ul {
			list-style: none;
			li {
				position: relative;
				padding-left: ac(36px, 16px);

				&:before {
					content: "";
					position: absolute;
					left: ac(13px, 0px);
					top: calc(ac(32px, 28px) / 2);
					transform: translateY(-50%);
					border-radius: 50%;
					width: 6px;
					height: 6px;
					background: var(--dark-grey);
				}

				/*&:not(:last-child) {
                  margin-bottom: 5px;
                }*/
			}
		}

		&.biggest-type {
			ul {
				li {
					&:before {
						top: calc(ac(44px, 26px) / 1.8);

						@mixin mob {
							top: calc(ac(44px, 26px) / 1.5);
						}
					}
				}
			}
		}

		&.bigger-type {
			ul {
				li {
					&:before {
						top: calc(ac(36px, 26px) / 2);

						@mixin mob {
							top: calc(ac(36px, 26px) / 1.8);
						}
					}
				}
			}
		}

		&.medium-type {
			ul {
				li {
					&:before {
						top: calc(ac(36px, 28px) / 1.9);

						@mixin mob {
							top: calc(ac(36px, 28px) / 1.8);
						}
					}
				}
			}
		}

		&.small-type {
			ul {
				li {
					&:before {
						top: calc(ac(32px, 24px) / 1.9);

						@mixin mob {
							top: calc(ac(32px, 24px) / 1.8);
						}
					}
				}
			}
		}

		&.smallest-type {
			ul {
				li {
					&:before {
						top: calc(ac(28px, 24px) / 1.9);

						@mixin mob {
							top: calc(ac(28px, 24px) / 1.8);
						}
					}
				}
			}
		}
	}

	ol {
		&:not(.sectors-article-styles) {
			list-style-type: none;
			counter-reset: num;

			ul {
				counter-reset: num;
			}

			li {
				padding-left: ac(38px, 16px);
				position: relative;

				&:before {
					position: absolute;
					color: var(--black);
					top: 0;
					left: ac(8px, 0px);
					font: inherit;
					content: counter(num) ".";
					counter-increment: num;
					transform: translateX(-4px);
					letter-spacing: -0.5px;
				}

				/*&:not(:last-child) {
                  margin-bottom: 5px;
                }*/
			}

			ol {
				counter-reset: num2;

				li {
					padding-left: ac(42px, 22px);

					&:before {
						transform: translateX(ac(-4px, -7px));
					}
				}

				ol {
					counter-reset: none;
				}

				ul {
					counter-reset: num2;
				}
			}

			ol li:before {
				content: counter(num) "." counter(num2);
				counter-increment: num2;
			}
		}

	}

	&.biggest-type {
		p,
		li {
			font: 500 ac(24px, 18px) / ac(44px, 26px) var(--font-main) !important;
			/*font-size: ac(24px, 18px);
			line-height: ac(44px, 26px);
			font-weight: 500;*/

			&:not(:last-child) {
				margin-bottom: ac(20px, 16px);
			}
		}
	}

	&.bigger-type {
		p,
		li {
			font: 500 ac(22px, 18px) / ac(36px, 26px) var(--font-main) !important;
			/*font-size: ac(22px, 18px);
			line-height: ac(36px, 26px);
			font-weight: 500;*/

			&:not(:last-child) {
				margin-bottom: ac(20px, 16px);
			}
		}
	}

	&.medium-type {
		p,
		li {
			font: 500 ac(20px, 16px) / ac(36px, 28px) var(--font-main) !important;
			/*font-size: ac(20px, 16px);
			font-weight: 500;
			line-height: ac(36px, 28px);*/

			&:not(:last-child) {
				margin-bottom: ac(16px, 14px);
			}
		}
	}

	&.small-type {
		p,
		li {
			font: 400 ac(18px, 14px) / ac(32px, 24px) var(--font-main) !important;
			/*font-size: ac(18px, 14px);
			line-height: ac(32px, 24px);*/
		}
	}

	&.smallest-type {
		p,
		li {
			font: 400 ac(16px, 14px) / ac(28px, 24px) var(--font-main) !important;
			/*font-size: ac(16px, 14px);
			line-height: ac(28px, 24px);*/

			&:not(:last-child) {
				margin-bottom: ac(12px, 10px);
			}
		}
	}

	&.centered-type {
		text-align: center;
		& > * {
			text-align: center;
		}
	}

	&.job-view-type {
		h2 {
			font: 500 ac(32px, 24px) / ac(48px, 30px) var(--font-main) !important;
			/*font-size: ac(32px, 24px);
			font-weight: 500;
			line-height: ac(48px, 30px);*/

			&:not(:last-child) {
				margin-bottom: ac(38px, 18px);
			}

			strong {
				font: 700 ac(34px, 24px) / ac(48px, 30px) var(--font-main) !important;
				/*font-size: ac(34px, 24px);
				font-weight: 700;
				line-height: ac(48px, 30px);*/
				margin-bottom: ac(-8px, -4px);
			}
		}

		h3 {
			font: 500 ac(22px, 20px) / ac(36px, 30px) var(--font-main) !important;
			/*font-size: ac(22px, 20px);
			font-weight: 500;
			line-height: ac(36px, 30px);*/
		}

		h3 + ol {
			max-width: 640px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-wrap: wrap;
			list-style-type: none;
			counter-reset: none;

			li {
				font: 400 ac(16px, 14px) / ac(28px, 24px) var(--font-main) !important;
				/*font-weight: 400;
				font-size: ac(16px, 14px);
				line-height: ac(28px, 24px);*/
				padding-left: 11px;
				padding-right: 11px;
				padding-top: 3px;
				padding-bottom: 3px;
				border: 1px solid var(--black);
				border-radius: 60px;
				margin-right: 8px;
				margin-bottom: 16px;

				&:before {
					content: none;
				}
			}

			&:not(:last-child) {
				padding-bottom: ac(50px, 20px);
			}
		}
	}

	&.checked-list-type {
		ul {
			list-style: none;

			li {
				font-weight: 600 !important;
				/*font-size: ac(16px, 14px);
				line-height: ac(28px, 24px);*/
				padding-left: ac(40px, 32px);
				position: relative;

				&:before {
					font-family: "icomoon" !important;
					speak: never;
					font-style: normal;
					font-weight: normal;
					font-variant: normal;
					text-transform: none;
					line-height: 1;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					position: absolute;
					content: "\e911";
					font-size: 16px;
					color: var(--black);
					top: 50%;
					left: ac(5px, 0px);
					transform: translateY(-50%);
				}

				&:not(:last-child) {
					margin-bottom: ac(16px, 4px);
				}
			}

			&:not(:last-child) {
				padding-bottom: ac(48px, 24px);
			}
		}
	}

	table {
		width: 100% !important;
		max-width: 100% !important;
	}
}

.dark-bg {
	.content-element {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p,
		li {
			a {
				color: var(--blue);
				text-decoration-color: var(--blue);

				&:hover {
					color: var(--light-grey);
					text-decoration-color: var(--light-grey);
				}
			}
		}

		&:not(.checked-list-type) {
			ul {
				li {
					&:before {
						background: var(--light-grey);
					}
				}
			}
		}

		ol {
			li {
				&:before {
					color: var(--white);
				}
			}
		}
	}
}
