@import "_sectors.pcss";
@import "_jobs.pcss";
@import "_testimonials.pcss";
@import "_contact.pcss";
@import "_expertise.pcss";
@import "_history.pcss";
@import "_benefits.pcss";
@import "_teams.pcss";
@import "_hiring.pcss";
@import "_clients.pcss";

.slider-cont {
	padding: 10px 0;
	max-width: 100%;
	margin: 0 auto;
	overflow: hidden;
}
