.approach-section {
	padding-top: 298px;
	padding-bottom: ac(144px, 64px);
	position: relative;
	overflow: hidden;
	z-index: 9;

	.line-title {
		top: ac(164px, 72px);
		left: 0;
		z-index: 2;
		position: absolute;

		h2 {
			transform: translateX(ac(-180px, 0px));
		}
	}

	&__bg {
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		z-index: -1;

		&:after {
			content: "";
			width: ac(1511px, 845px);
			height: ac(1641px, 916px);
			position: absolute;
			background-image: url("../images/decors/blue-decor.svg");
			background-size: contain;
			background-repeat: no-repeat;
			right: 0;
			top: 0;
			transform: translateX(45%) translateY(-43%);
			z-index: 2;
			mix-blend-mode: overlay;

			@mixin mob-lg {
				transform: translateX(38%) translateY(-52%);
			}

			@mixin mob {
				transform: translateX(547px) translateY(-144px);
			}

			@mixin min-media 1450 {
				transform: translateX(35%) translateY(-43%);
			}

			@mixin min-media 1650 {
				transform: translateX(25%) translateY(-43%);
			}

			@mixin min-media 1900 {
				transform: translateX(20%) translateY(-43%);
			}
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			filter: grayscale(100%);
			object-position: top;

			@mixin media 1480 {
				@mixin min-media 1400 {
					transform: scale(1.05) translateX(2.7%) translateY(2.3%);
				}
			}

			&.blur-up {
				filter: blur(3px) grayscale(100%);
				transition: filter 0.4s;
			}

			&.blur-up.lazyloaded {
				filter: blur(0) grayscale(100%);
			}
		}

		&:before {
			content: "";
			position: absolute;
			inset: 0;
			background: linear-gradient(
				33.94deg,
				rgba(13, 13, 13, 0.647) 22.19%,
				rgba(13, 13, 13, 0.204) 91.24%
			);
			z-index: 1;

			@mixin mob {
				background: linear-gradient(
					180deg,
					rgba(13, 13, 13, 0) 0%,
					rgba(13, 13, 13, 0.6) 100%
				);
			}
		}
	}

	&__wrap {
		width: 100%;
		max-width: ac(958px, 600px);
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		z-index: 1;
	}

	.subtitle {
		max-width: ac(400px, 343px);
		margin-right: auto;

		&:not(:last-child) {
			margin-bottom: ac(22px, 24px);
		}

		@mixin media 374 {
			max-width: 100%;
		}
	}

	.content-element {
		width: 100%;
		max-width: ac(632px, 500px);

		@mixin mob-lg {
			max-width: 100%;
		}
	}

	@mixin mob {
		padding-top: 328px;
	}
}
