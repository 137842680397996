.offers-section {
	padding-bottom: 160px;
	z-index: 2;
	background: var(--black);
	overflow: visible;
	padding-top: ac(294px, 200px);

	@mixin tab-sm {
		overflow: hidden;
	}

	@mixin mob {
		padding-bottom: 80px;
	}

	.cont {
		position: relative;
	}

	.line-title {
		position: absolute;
		top: ac(-122px, -100px);
		left: -20px;
		z-index: 1;
		text-align: start;
		justify-content: flex-start;

		@mixin mob-xl {
			left: 0;
		}

		@mixin mob-lg {
			h2 {
				font-size: 24px;
			}
		}

		@mixin mob {
			h2 {
				font-size: 22px;
			}
		}

		@mixin mob-sm {
			h2 {
				font-size: 20px;
			}
		}
	}

	&__decor {
		right: ac(-194px, -130px);
		top: ac(-480px, -280px);
		@mixin aspect-ratio 566, 615;
		position: absolute;
		width: ac(566px, 300px);
		z-index: 2;
		overflow: visible;
		mask-image: none;
		img {
			object-fit: contain;

			&.blur-up {
				transition: filter 0.4s, transform 0.1s ease;
			}
		}

		@mixin tab-sm {
			right: -210px;
		}

		@mixin mob-xl {
			display: none;
		}
	}

	&__wrap {
		position: relative;
		z-index: 3;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@mixin tab-sm {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 47.5%;

		@mixin tab-sm {
			padding-top: 20px;
			width: 100%;
			margin-bottom: 32px;
		}
	}

	&__title {
		width: 100%;
		max-width: ac(389px, 320px);
		margin-bottom: ac(32px, 24px);
		line-height: ac(48px, 30px);
	}

	&__form {
		min-height: ac(403px, 300px);
		width: 48.75%;
		background: linear-gradient(
			270deg,
			rgba(255, 255, 255, 0.15) 0%,
			rgba(0, 0, 0, 0.15) 100%
		);
		background-blend-mode: screen;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);
		backdrop-filter: blur(20px);
		border: 1px solid color-mod(var(--grey) a(5%));
		/*border-image-source: linear-gradient(
			212.47deg,
			rgba(248, 248, 248, 0.3) 0%,
			rgba(248, 248, 248, 0) 100%
		);*/
		padding: ac(48px, 20px);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;

		.default-input {
			width: 100%;

			input {
				&::placeholder {
					color: var(--white);
				}
			}
		}

		.btn {
			margin-top: 24px;
			margin-left: auto;
			min-height: ac(56px, 48px);

			@mixin mob-lg {
				width: 100%;
			}
		}

		@mixin tab-sm {
			width: 100%;
		}
	}
}
