.hiring-card {
	border: 2px solid var(--black);
	padding: ac(32px, 24px);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	height: ac(400px, 340px);
	background: var(--white);

	&__title {
		font-size: ac(32px, 24px);
		font-weight: 500;
		line-height: ac(48px, 30px);
		margin-bottom: 16px;
	}

	&__text {
		width: 100%;
		max-height: calc(ac(32px, 28px) * 6);

		.simplebar-track.simplebar-vertical {
			width: 4px;
			background: transparent;
			border-radius: 2px;
			transform: translateX(6px);

			.simplebar-scrollbar {
				background: var(--black);
				border-radius: 2px;

				&:before {
					content: none;
				}
			}
		}
	}

	.btn {
		margin-top: auto;
		border-color: var(--black);
		height: 56px;

		@mixin tab {
			height: auto;
		}
	}
}
