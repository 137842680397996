.plyr__control--overlaid {
	background: linear-gradient(
		270.32deg,
		#444444 -3.4%,
		#282828 30.65%,
		#0d0d0d 42.35%,
		#282828 54.06%,
		#000000 62.57%,
		#0d0d0d 74.27%,
		#444444 103%
	);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.24);
	border: 2px solid transparent;

	&:hover {
		background: linear-gradient(
			270.32deg,
			#444444 -3.4%,
			#282828 30.65%,
			#0d0d0d 42.35%,
			#282828 54.06%,
			#000000 62.57%,
			#0d0d0d 74.27%,
			#444444 103%
		) !important;

		border-color: var(--white);
	}

	--plyr-control-icon-size: ac(36px, 24px);
}

.plyr-hide-controls {
	.plyr__controls {
		opacity: 0;
	}
}

.plyr__poster {
	background-size: cover;
}

.video {
}
