.history-slider {
	overflow: visible;

	&__item {
		background: var(--black);
		padding-left: ac(48px, 24px);
		padding-right: ac(160px, 50px);
		padding-top: ac(134px, 94px);
		padding-bottom: ac(64px, 32px);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		height: ac(448px, 330px);
		overflow: visible !important;

		&.swiper-slide-active,
		&.swiper-slide-prev {
			.history-slider__date {
				color: var(--blue);
				background: var(--white);
				width: 70.81%;
				max-width: ac(507px, 300px);
				height: ac(120px, 80px);
				transform: translateX(ac(48px, 24px)) translateY(-26px);

				time {
					transform: rotate(0) translateY(0) scale(1);
				}
			}
		}

		&.swiper-slide-prev {
			position: relative;
			z-index: -2;
		}

		.content-element {
			width: 100%;
			max-height: calc(ac(36px, 26px) * 8);

			.simplebar-track.simplebar-vertical {
				width: 4px;
				background: transparent;
				border-radius: 2px;
				transform: translateX(6px);

				.simplebar-scrollbar {
					background: var(--snow);
					border-radius: 2px;

					&:before {
						content: none;
					}
				}
			}
		}
	}

	&__date {
		color: var(--grey);
		background: var(--light-grey);
		font-size: ac(80px, 46px);
		font-weight: 800;
		line-height: 130%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 8px 24px 8px ac(48px, 10px);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		transition: all 0.55s ease;
		width: 100%;
		height: 100%;
		transform: translateX(0) translateY(0);

		time {
			transition: all 0.55s ease;
			transform: rotate(-90deg) translateY(-50%) scale(1.29);
		}

		@mixin mob-sm {
			font-size: 36px;
		}
	}
}

.history-slider-buttons {
	margin-left: ac(48px, 24px);
	.slider-btn {
		margin-top: 24px;
	}
}
