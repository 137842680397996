.btn {
	background: var(--white);
	padding: ac(9px, 8px) 38px;
	position: relative;
	overflow: hidden;
	border: 2px solid var(--white);
	transition: border-color 0.25s ease, transform 0.25s ease;
	cursor: pointer;

	&.btn-small {
		padding: 2px 38px;

		span {
			font-size: 16px;
			line-height: 28px;
		}
	}

	&:before {
		position: absolute;
		inset: 0;
		content: "";
		background: var(--blue);
		opacity: 0;
		border-radius: 100px;
		transition: all 0.3s ease;
		z-index: 1;
		transform: scale(0) rotate(-12deg);
	}

	span {
		font-size: 18px;
		font-weight: 700;
		line-height: ac(24px, 22px);
		color: var(--black);
		position: relative;
		z-index: 2;
	}

	&:hover {
		&:before {
			transform: scale(1) rotate(0deg);
			opacity: 1;
			border-radius: 0;
		}
	}

	&:active {
		border-color: var(--grey);
		transform: scale(0.9);
	}

	&.btn-dark {
		background: var(--black);
		border-color: var(--black);

		&:before {
			transform: scale(0) rotate(12deg);
		}

		span {
			color: var(--white);
		}

		&:hover {
			span {
				color: var(--black);
			}

			&:before {
				background: var(--snow);
				transform: scale(1) rotate(0);
			}
		}
	}

	&.btn-snow {
		background: var(--snow);
		border-color: var(--snow);
	}

	&.btn-border {
		border-color: var(--black);

		&:hover {
			border-color: var(--black);
		}
	}

	&.btn-with-icon {
		i {
			font-size: 17px;
			margin-right: 8px;
		}

		span {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.buttons {
	display: flex;
	justify-content: center;
	align-items: center;

	.btn {
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}

.scroll-toggle {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	&__icons {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-left: auto;
		margin-bottom: 5px;
	}

	&__icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 10px;
		height: 10px;
		position: relative;
		color: var(--blue);
		transition: all 0.3s ease;
		border-radius: 50%;

		i {
			position: absolute;
			top: 50%;
			left: 50%;
			font-size: 9px;
			transform: translateY(-50%) translateX(-50%);
			transition: all 0.3s ease;

			&.icon-arrow-down {
				opacity: 0;
			}
		}

		&:not(:last-child) {
			margin-right: 5px;
		}

		&:first-child {
			transition-delay: 0.15s;
		}
	}

	&:not(.active) {
		.scroll-toggle__icon {
			&:first-child {
				animation: transform-first-plus 3s infinite;

				i {
					animation: opacity-plus 3s infinite;

					&.icon-arrow-down {
						animation: opacity-arrow 3s infinite;
					}
				}
			}

			&:last-child {
				animation: pulse 0.5s infinite;
			}
		}
	}

	&.active {
		.scroll-toggle__icon {
			&:first-child {
				animation: transform-hover-plus 0.5s forwards;

				i {
					animation: opacity-hover-plus 0.5s forwards;

					&.icon-arrow-down {
						animation: opacity-hover-arrow 0.5s forwards;
					}
				}
			}

			&:last-child {
				animation: transform-hover-plus 0.5s forwards;
				animation-delay: 0.15s;

				i {
					animation: opacity-hover-plus 0.5s forwards;
					animation-delay: 0.15s;

					&.icon-arrow-down {
						animation: opacity-hover-arrow 0.5s forwards;
						animation-delay: 0.15s;
					}
				}
			}
		}
	}
}

&__text {
	font-size: ac(18px, 16px);
	line-height: ac(24px, 20px);
	font-family: var(--font-main);
	color: var(--white);
	transition: color 0.25s ease;
	position: relative;
	z-index: 1;
}

/*&:hover {
	.scroll-toggle__icon {
		transform: translateY(calc(ac(26px, 22px) + 15px));

		&:first-child {
			animation: transform-hover-plus 0.5s forwards;
		}

		&:last-child {
			animation: transform-hover-plus 0.5s forwards;
			animation-delay: 0.15s;
		}

		i {
			opacity: 0;
			animation: none;
			&.icon-arrow-down {
				opacity: 1;
				animation: none;
			}
		}
	}
}*/

@keyframes transform-first-plus {
	0% {
		transform: translateY(0);
	}
	30% {
		transform: translateY(calc(ac(26px, 22px) + 30px));
	}
	50% {
		transform: translateY(calc(ac(26px, 22px) + 30px)) translateX(14px)
			rotate(-90deg);
	}

	75% {
		transform: translateY(0) translateX(14px) rotate(-180deg);
	}

	90% {
		transform: translateY(0) translateX(14px);
	}
}

@keyframes transform-hover-plus {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(calc(ac(26px, 22px) + 30px));
	}
}

@keyframes opacity-plus {
	0% {
		opacity: 1;
	}
	30% {
		opacity: 0;
	}
	60% {
		opacity: 0;
	}

	80% {
		opacity: 0;
	}

	90% {
		opacity: 1;
	}
}

@keyframes opacity-hover-plus {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes opacity-arrow {
	0% {
		opacity: 0;
	}
	30% {
		opacity: 1;
	}
	60% {
		opacity: 1;
	}

	80% {
		opacity: 0;
	}

	90% {
		opacity: 0;
	}
}

@keyframes opacity-hover-arrow {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 color-mod(var(--white) a(70%));
		border-radius: 50%;
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 3px color-mod(var(--white) a(10%));
		border-radius: 50%;
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 color-mod(var(--white) a(0%));
		border-radius: 50%;
	}
}

.socials {
	display: flex;
	align-items: center;
	justify-content: center;
	.social-btn {
		margin: 0 ac(6px, 4px);
	}
}

.social-btn {
	font-size: 40px;
	border: 2px solid var(--black);
	color: var(--black);
	background: var(--white);
	transition: all 0.25s ease;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		background: var(--blue);
	}

	&[href^="tel:"] {
		position: relative;


		&:before {
			background-color: var(--snow);
			top: -5px;
			left: 50%;
			transform: translateY(-100%) translateX(-50%);
			padding: 4px;
			border-radius: 4px;
			position: absolute;
			content: attr(data-phone);
			z-index: 40;
			font-size: 12px;
			line-height: 1;
			font-weight: 500;
			pointer-events: none;
			transition: all .3s ease;
			opacity: 0;
			box-shadow: 0 0 4px rgba(0,0,0,.24);
			white-space: nowrap;

			@mixin tab-sm {
				display: none;
			}
		}

		&:hover {
			&:before {
				opacity: 1;
			}
		}
	}


}
