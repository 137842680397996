.figure-text-section {
	padding-top: ac(312px, 160px);
	padding-bottom: ac(80px, 24px);
	overflow: visible;
	z-index: 5;
	background: var(--snow);

	.cont {
		position: relative;
	}

	&.financial-services-type {
		padding-top: ac(176px, 120px);
		.line-title {
			padding-left: ac(48px, 0px);
			top: ac(-114px, -102px);
		}

		.figure-text-section__image {
			top: ac(-260px, -100px);
		}
	}

	&.general-insurance-type {
		padding-top: ac(176px, 120px);
		.line-title {
			top: ac(-114px, -102px);
		}

		.figure-text-section__image {
			top: ac(-278px, -100px);
		}
	}

	.line-title {
		position: absolute;
		top: ac(-144px, -102px);
		left: 0;
		z-index: 1;
		text-align: left;
		justify-content: left;
		padding-left: ac(108px, 0px);
	}

	&__wrap {
		width: 100%;
		position: relative;
		z-index: 2;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		padding-left: ac(109px, 0px);
	}

	&__title {
		margin-bottom: ac(32px, 24px);
		max-width: ac(516px, 370px);

		h3 {
			font-size: ac(32px, 24px);
			font-weight: 500;
			line-height: ac(48px, 30px);
		}
	}

	&__text {
		margin-left: ac(109px, 24px);
		max-width: ac(625px, 400px);

		@mixin mob-lg {
			max-width: 100%;
			margin-left: 0;
		}

		&:not(:last-child) {
			margin-bottom: ac(32px, 24px);
		}
	}

	&__buttons {
		margin-left: ac(109px, 24px);

		.btn:not(:last-child) {
			margin-right: ac(20px, 10px);
		}

		@mixin mob-lg {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			margin-left: 0;

			.btn {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 48px;
			}

			.btn:not(:last-child) {
				margin-right: 0;
				margin-bottom: 16px;
			}
		}
	}

	&__image {
		@mixin aspect-ratio 644, 700;
		width: ac(644px, 360px);
		position: absolute;
		top: ac(-284px, -100px);
		right: ac(-223px, -120px);
		clip-path: url("#figure-clip-path");

		@mixin tab-sm {
			right: -120px;
		}

		@mixin media 750 {
			right: -180px;
		}

		@mixin mob-xl {
			width: 90%;
			max-width: 380px;
			top: auto !important;
			right: auto;
			position: relative;
			margin-bottom: 55px;
			margin-left: auto;
			margin-right: auto;
		}

		&.blue-gradient-img {
			img {
				filter: grayscale(100%);
				&.blur-up {
					filter: blur(3px) grayscale(100%);
					transition: filter 0.4s;
				}

				&.blur-up.lazyloaded {
					filter: blur(0) grayscale(100%);
				}
			}

			&:after {
				position: absolute;
				inset: 0;
				content: "";
				background: linear-gradient(
					0deg,
					rgba(86, 222, 246, 0.3),
					rgba(86, 222, 246, 0.3)
				);
				mix-blend-mode: screen;
			}
		}
	}
}
