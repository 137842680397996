@import url("https://fonts.googleapis.com/css2?family=Schibsted+Grotesk:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
	--black: #0d0d0d;
	--bg-second: #343434;
	--main: #3cc5fb;
	--snow: #f8f8f8;
	--white: #ffffff;
	--blue: #8ce4fb;
	--light-grey: #e6e6e6;
	--grey: #a6a6a6;
	--dark-grey: #565656;
	--primary: #ffde33;
	--secondary: #088ded;

	--font-main: "Schibsted Grotesk", sans-serif;

	/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
	--mobile-menu-start-point: 1100;

	--gradient-main: linear-gradient(
		270deg,
		#61dff7 0%,
		#4edef5 7.9%,
		#19d1ed 37.4%,
		#00b8d3 48.8%,
		#19d1ed 58.6%,
		#008093 67.5%,
		#00b8d3 78.8%,
		#5fe6fd 100%
	);
	--gradient-second: linear-gradient(
		90deg,
		#61dff7 0%,
		#4edef5 7.9%,
		#19d1ed 37.4%,
		#00b8d3 48.8%,
		#19d1ed 58.6%,
		#008093 67.5%,
		#00b8d3 78.8%,
		#5fe6fd 100%
	);

	--plyr-color-main: var(--blue);
	--plyr-control-spacing: ac(16px, 14px);
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
@custom-media --mobile-menu-start-point (max-width: 1100px);

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
@custom-media --mobile-menu-end-point (min-width: 1101px);
