.jobs-search-section {
	padding-top: ac(22px, 40px);
	padding-bottom: ac(80px, 60px);
	overflow: visible;
	position: relative;
	z-index: 2;

	&__wrap {
		display: flex;
		justify-content: space-between;

		@mixin tab-sm {
			position: relative;
			width: 100%;
		}
	}

	.sidebar {
		width: 23.13%;
		max-width: 280px;
		flex-shrink: 0;
		order: 2;

		@mixin tab-sm {
			order: initial;
		}

		&__buffer {
			display: block;
		}

		&__inner {
			position: sticky;
			padding-bottom: 60px;
			padding-top: ac(172px, 120px);

			@mixin tab-sm {
				padding-top: 0;
			}
		}

		&__title {
			font-size: ac(34px, 24px);
			font-weight: 700;
			line-height: ac(48px, 30px);
			position: relative;

			&:not(:last-child) {
				margin-bottom: ac(48px, 24px);
			}

			.btn-filter-close {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				width: 40px;
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 2px solid var(--black);
				color: var(--black);
				opacity: 0;
				pointer-events: none;
				cursor: pointer;
				font-size: 40px;

				&.active {
					opacity: 1;
					pointer-events: auto;
				}
			}

			@mixin tab-sm {
				padding-right: 44px;
			}
		}

		&__item {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			padding-bottom: ac(40px, 24px);
			width: 100%;

			&:not(:first-child) {
				.sidebar {
					&__item-top {
						margin-top: ac(30px, 20px);
					}
				}
			}

			.double-range-container {
				max-width: 251px;
			}

			.checkbox {
				.checkbox-box {
					border-color: var(--grey);
				}

				.checkbox-text {
					transition: all 0.15s ease;
				}
			}

			.checkbox input:checked ~ .checkbox-box {
				border-color: var(--black);
			}

			.checkbox input:checked ~ .checkbox-text {
				font-weight: 600;
			}

			h3.sidebar__item-title {
				font-size: ac(22px, 20px);
				font-weight: 500;
				line-height: ac(36px, 30px);

				&:not(:last-child) {
					margin-bottom: ac(24px, 16px);
				}
			}

			h4.sidebar__item-title {
				font-size: ac(18px, 18px);
				font-weight: 700;
				line-height: ac(36px, 30px);

				&:not(:last-child) {
					margin-bottom: ac(16px, 14px);
				}
			}

			.btn-show-hide {
				margin-top: ac(32px, 24px);
				display: flex;
				align-items: center;
				justify-content: flex-start;
				min-width: 132px;
				cursor: pointer;
				padding-left: 18px;
				padding-right: 24px;

				i {
					font-size: 16px;
					margin-right: 8px;
					position: relative;
					z-index: 1;
					transition: transform 0.2s ease;
				}

				span {
					font-weight: 600;
				}

				&__show {
					display: flex;
				}

				&__hide {
					display: none;
				}

				&:not(.is-visible) {
					opacity: 0;
					display: none;
				}

				&.active {
					.btn-show-hide__show {
						display: none;
					}

					.btn-show-hide__hide {
						display: flex;
					}

					i {
						transform: rotate(-45deg);
					}
				}

				&:hover {
					i {
						color: var(--black);
					}
				}
			}
		}

		&__item-top {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			position: relative;
		}

		&__item-content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			width: 100%;

			ul {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				width: 100%;

				li {
					&:not(:first-child) {
						margin-top: ac(16px, 8px);
					}

					&.is-hidden {
						display: none;
					}
				}
			}
		}

		@mixin tab-sm {
			position: absolute;
			width: 300px;
			top: 0;
			left: 0;
			z-index: 1;
			max-width: 300px;
			transition: transform 0.3s ease;
			transform: translateX(-120%);

			&.active {
				transform: translateX(0);
			}

			.sidebar {
				&__buffer {
					display: none;
				}

				&__inner {
					position: static;
				}
			}

			@mixin mob {
				width: 270px;
				max-width: 270px;
			}

			@mixin mob-sm {
				width: 100%;
				max-width: 100%;
			}
		}
	}

	.jobs-list {
		width: 74.38%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		z-index: 1;
		order: 1;

		@mixin tab-sm {
			order: initial;
		}

		&__result {
			width: 100%;
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			grid-column-gap: ac(32px, 18px);
			grid-row-gap: ac(30px, 20px);

			@mixin mob-lg {
				grid-template-columns: repeat(1, minmax(0, 1fr));
			}
		}

		&__top {
			padding-bottom: ac(50px, 34px);
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.default-select {
				width: 240px;
			}

			.choices__list.choices__list--single .choices__item {
				position: relative;

				&:before {
					content: "Sort by -";
					font-weight: 500;
					font-size: 14px;
					line-height: 28px;
					color: var(--dark-grey);
					margin-right: 4px;
				}
			}

			@mixin tab-sm {
				align-items: flex-start;
			}

			@mixin mob-lg {
				flex-direction: column;

				.jobs-list__title {
					margin-bottom: 16px;
				}

				.default-select {
					width: 100%;
				}
			}
		}

		.pagination {
			margin-top: ac(30px, 24px);
		}

		@mixin tab-sm {
			width: 100%;
			transition: transform 0.3s ease;
			transform: translateX(0);

			&:before {
				position: absolute;
				content: "";
				left: -20px;
				bottom: 0;
				right: 0;
				height: 70%;
				background: linear-gradient(
					135deg,
					color-mod(var(--white) a(0%)) 0%,
					var(--white) 50%,
					var(--white) 100%
				);
				opacity: 0;
				pointer-events: none;
				z-index: 1;
				transition: opacity 0.3s ease;
			}

			.job-card {
				position: relative;

				&:before {
					position: absolute;
					content: "";
					inset: 0;
					background: linear-gradient(
						90deg,
						color-mod(var(--white) a(10%)) 0%,
						var(--white) 100%
					);
					opacity: 0;
					pointer-events: none;
					z-index: 1;
					transition: opacity 0.3s ease;

					@mixin mob-lg {
						background: linear-gradient(
							90deg,
							color-mod(var(--white) a(10%)) 0%,
							var(--white) 50%,
							var(--white) 100%
						);
					}
				}
			}

			&.active {
				transform: translateX(330px);

				&:before {
					opacity: 1;
				}

				.job-card {
					pointer-events: none;
					cursor: initial;
					&:before {
						pointer-events: auto;
						opacity: 1;
					}
				}

				@mixin mob {
					transform: translateX(290px);
				}

				@mixin mob-sm {
					transform: translateX(330px);
				}
			}
		}
	}

	&__wrap {
		@mixin tab-sm {
			&.active {
				/*clip-path: inset(0 -50px 50px -50px);*/
				.jobs-list {
					position: absolute;
					top: 0;
					left: 0;
				}

				.sidebar {
					position: static;
				}
			}
		}
	}

	.btn-filters {
		position: sticky;
		bottom: 24px;
		left: 50%;
		transform: translateX(-50%);
		margin-top: 24px;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);
		width: min-content;
		z-index: 50;
		display: none;
		min-height: ac(56px, 48px);

		@mixin tab-sm {
			display: flex;
		}

		@mixin mob-lg {
			width: 100%;
			transform: none;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	@mixin tab-sm {
		overflow: hidden;
	}
}
