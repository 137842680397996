.submit-section {
	padding-top: ac(192px, 88px);
	background: var(--blue);
	padding-bottom: ac(120px, 60px);
	margin-top: ac(-128px, -62px);
	overflow: visible;

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 200px;
		background: var(--blue);
		transform: translateY(-98%);
		content: "";
	}

	@mixin min-media 1850 {
		padding-top: 7.29vw;
	}

	&__wrap {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		max-width: ac(1062px, 900px);
		margin: 0 auto;
	}

	.submit-top-container {
		width: 100%;
		display: flex;
		position: relative;

		&__item {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			transition: opacity 0.35s ease;
			pointer-events: none;
			opacity: 0;

			&.active {
				left: auto;
				top: auto;
				position: relative;
				pointer-events: auto;
				opacity: 1;

				.submit-top-container__item-title {
					transform: rotate(5deg);
					&.dark-bg {
						transform: rotate(-5deg);
					}
				}
			}
		}

		&__item-title {
			padding-top: ac(10px, 19px);
			padding-bottom: ac(10px, 19px);
			background: var(--snow);
			display: flex;
			justify-content: center;
			align-items: center;
			width: auto;
			transform: rotate(0deg);
			z-index: 5;
			margin-bottom: ac(68px, 50px);
			transition: transform 0.3s ease;
			min-width: ac(600px, 500px);
			max-width: 100%;

			&.dark-bg {
				background: var(--black);
				color: var(--snow);
			}
		}

		&__item-text {
			margin-bottom: ac(38px, 24px);
			max-width: 844px;
			width: 100%;
		}
	}

	.submit-center-container {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: ac(40px, 32px);

		.submit-tab-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			font-family: var(--font-main);
			font-size: ac(18px, 16px);
			font-weight: 700;
			line-height: ac(22px, 20px);
			color: var(--black);
			padding: 17px ac(40px, 20px) 13px;
			border-bottom: 4px solid transparent;
			transition: border-color 0.3s ease;
			cursor: pointer;
			margin: 0 4px;
			text-align: center;

			&.active {
				border-color: var(--black);
			}

			&:hover {
				&:not(.active) {
					border-color: var(--grey);
				}
			}

			@mixin mob-sm {
				font-size: 14px;
			}
		}
	}

	.submit-form-container {
		display: flex;
		position: relative;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;

		.submit-form {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			transition: opacity 0.35s ease;
			pointer-events: none;
			opacity: 0;
			padding: ac(80px, 40px) ac(80px, 20px) ac(64px, 38px);
			background: var(--white);

			&.dark-bg {
				background: var(--black);
			}

			&.active {
				left: auto;
				top: auto;
				position: relative;
				pointer-events: auto;
				opacity: 1;
			}

			&__grid {
				display: grid;
				grid-template-columns: repeat(2, minmax(0, 1fr));
				grid-column-gap: ac(30px, 16px);
				grid-row-gap: ac(40px, 20px);

				@mixin tab-sm {
					.default-file-upload {
						grid-column: 1/-1;
						@mixin aspect-ratio-block 436, 240;
						overflow: visible;
					}

					.default-input {
						&.with-full {
							grid-column: 1/-1;
						}
					}
				}

				@mixin mob-lg {
					grid-template-columns: repeat(1, minmax(0, 1fr));

					.default-file-upload {
						@mixin aspect-ratio-block 436, 260;
						overflow: visible;
					}
				}

				@mixin mob {
					.default-file-upload {
						@mixin aspect-ratio-block 436, 320;
						overflow: visible;
					}
				}

				@mixin media 375 {
					.default-file-upload {
						@mixin aspect-ratio-block 436, 340;
						overflow: visible;
					}
				}

				@mixin mob-sm {
					.default-file-upload {
						@mixin aspect-ratio-block 436, 420;
						overflow: visible;
					}
				}
			}

			.btn-submit {
				position: absolute;
				right: ac(80px, 20px);
				bottom: 0;
				transform: translateY(50%);
				z-index: 1;
				min-width: ac(217px, 180px);

				&:before {
					background: var(--black);
				}

				&:hover {
					span {
						color: var(--snow);
					}
				}
			}
		}

		.checkbox {
			margin-top: ac(52px, 32px);
			.checkbox-box {
				margin-right: ac(16px, 8px);
				border-color: var(--grey);
			}

			.checkbox-text {
				color: var(--dark-grey);
				font-size: 14px;
				font-weight: 500;

				a {
					color: var(--black);

					&:hover {
						color: var(--blue);
						text-decoration-color: var(--blue);
					}
				}

				@mixin mob-lg {
					br {
						display: none;
					}
				}
			}

			&.dark-bg {
				border-color: var(--snow);

				.checkbox-text {
					color: var(--grey);

					a {
						color: var(--blue);

						&:hover {
							text-decoration-color: var(--dark-grey);
						}
					}
				}
			}
		}
	}
}
