.hiring-section {
	padding-top: ac(168px, 160px);
	padding-bottom: ac(80px, 40px);
	overflow: hidden;
	background: var(--snow);

	.cont {
		position: relative;
	}

	.line-title {
		position: absolute;
		top: ac(-104px, -130px);
		left: 0;
		z-index: 1;
		text-align: left;
		justify-content: left;
		padding-left: ac(78px, 0px);

		&__bg {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%) translateY(-99%);
			height: 300px;
			background: var(--black);
			width: 200%;
		}
	}

	&__wrap {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
	}

	&__content {
		padding-left: ac(90px, 0px);
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-direction: column;
		width: 48.75%;
		height: ac(400px, 340px);
		margin-bottom: ac(110px, 32px);

		@mixin tab-sm {
			height: auto;
			width: 100%;
		}

		@mixin mob {
			max-width: 100%;
		}
	}

	&__subtitle {
		max-width: ac(460px, 340px);

		&:not(:last-child) {
			margin-bottom: ac(24px, 12px);
		}
	}

	&__text {
		max-width: ac(460px, 340px);
		width: 100%;
		max-height: calc(ac(36px, 24px) * 6);

		.simplebar-track.simplebar-vertical {
			width: 4px;
			background: transparent;
			border-radius: 2px;
			transform: translateX(4px);

			.simplebar-scrollbar {
				background: var(--black);
				border-radius: 2px;

				&:before {
					content: none;
				}
			}
		}

		&.small-type {
			p,
			li {
				font-weight: 700;
				line-height: ac(36px, 24px);
			}
		}

		@mixin tab-sm {
			max-height: none;
		}

		@mixin mob {
			max-width: 100%;
		}
	}
}
