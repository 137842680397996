.contact-slider {
	width: 100%;
	max-width: 100%;
	overflow: visible;
	clip-path: inset(-1000px 0 -1000px 0);
}

.contact-slider-nav {
	margin-top: 20px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.contact-slider-submit {
		display: none;
	}

	.contact-slider-prev {
		&.swiper-button-disabled {
			pointer-events: none;
			opacity: 0;
		}

		.btn__icon {
			transform: scale(-1, 1);
			margin-right: ac(10px, 8px);
			margin-left: 0;
		}

		&:hover {
			.btn__icon {
				transform: scale(-1, 1) translateX(6px);
			}
		}
	}
}
