.hiring-slider {
	width: 100%;
	overflow: visible;

	&:not(.swiper-initialized) {
		.swiper-wrapper {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			position: relative;
			max-width: 1200px;
			margin: 0 auto;
		}

		.hiring-slider-buttons {
			display: none;
		}

		.hiring-card {
			width: 45.42%;
			&:first-child {
				position: absolute;
				top: ac(-110px, -32px);
				right: 0;
				transform: translateY(-100%);
			}
		}
	}

	&.swiper-initialized {
		clip-path: inset(-4px);
	}
}

.hiring-slider-buttons {
	justify-content: center;
	.slider-btn {
		margin-top: 24px;

		&.swiper-button-lock {
			display: none;
		}
	}
}
