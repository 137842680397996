@font-face {
	font-family: "icomoon";
	src: url("../fonts/icomoon.eot?yvkfed");
	src: url("../fonts/icomoon.eot?yvkfed#iefix") format("embedded-opentype"),
		url("../fonts/icomoon.woff2?yvkfed") format("woff2"),
		url("../fonts/icomoon.ttf?yvkfed") format("truetype"),
		url("../fonts/icomoon.woff?yvkfed") format("woff"),
		url("../fonts/icomoon.svg?yvkfed#icomoon") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "icomoon" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-arrow-next-btn:before {
	content: "\e90a";
}
.icon-arrow-prev-btn:before {
	content: "\e90b";
}
.icon-arrow-upload:before {
	content: "\e90c";
}
.icon-check:before {
	content: "\e910";
}
.icon-check-list:before {
	content: "\e911";
}
.icon-close:before {
	content: "\e912";
}
.icon-email:before {
	content: "\e913";
}
.icon-linkedin-2:before {
	content: "\e918";
}
.icon-location:before {
	content: "\e919";
}
.icon-minus-btn:before {
	content: "\e91a";
}
.icon-permanent:before {
	content: "\e91b";
}
.icon-phone:before {
	content: "\e91c";
}
.icon-plus-btn:before {
	content: "\e91d";
}
.icon-salary:before {
	content: "\e91e";
}
.icon-upload-check:before {
	content: "\e91f";
}
.icon-upload-close:before {
	content: "\e920";
}
.icon-arrow-burger:before {
	content: "\e909";
}
.icon-arrow-down:before {
	content: "\e908";
}
.icon-quote-bottom:before {
	content: "\e901";
}
.icon-quote-top:before {
	content: "\e903";
}
.icon-arrow-next:before {
	content: "\e904";
}
.icon-arrow-prev:before {
	content: "\e905";
}
.icon-plus:before {
	content: "\e906";
}
.icon-facebook:before {
	content: "\e900";
}
.icon-linkedin:before {
	content: "\e902";
}
.icon-twitter:before {
	content: "\e907";
}
