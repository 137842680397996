.latest-jobs-section {
	padding-top: ac(157px, 94px);
	overflow: visible;
	z-index: 10;
	margin-bottom: -100px;

	&__wrap {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		z-index: 10;
	}

	.line-title {
		position: absolute;
		top: ac(42px, 10px);
	}

	@mixin mob-lg {
		margin-bottom: -215px;
	}

	&.internal-jobs {
		margin-bottom: 0;
		padding-bottom: ac(120px, 80px);

		.jobs-slider {
			transform: translateX(ac(110px, 0px));
		}

		+ .offers-section {
			margin-top: ac(-45px, 0px);
		}
	}
}
