.text-photo-section {
	background: var(--snow);
	padding-top: ac(130px, 120px);
	padding-bottom: ac(48px, 40px);
	overflow: visible;

	.cont {
		position: relative;
	}

	.line-title {
		position: absolute;
		top: ac(-62px, -44px);
		left: 0;
		z-index: 1;
		text-align: end;
		justify-content: flex-end;
	}

	&__wrap {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		z-index: 2;

		@mixin tab-md {
			flex-direction: column;
		}
	}

	&__content {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		width: 40.31%;

		.content-element {
			margin-bottom: ac(38px, 24px);
		}

		@mixin tab-md {
			width: 100%;

			.content-element {
				@mixin min-media 552 {
					max-width: 90%;
				}
			}

			&:not(:last-child) {
				margin-bottom: ac(32px, 28px);
			}

			&:first-child {
				padding-top: 30px;
			}
		}
	}

	&__image {
		width: 55.94%;
		@mixin aspect-ratio 716, 458;

		@mixin tab-md {
			width: 100%;

			&:not(:last-child) {
				margin-bottom: ac(32px, 28px);
			}

			&:first-child {
				margin-top: 40px;
			}
		}
	}

	+ .testimonials-section {
		background: var(--snow);
	}
}
