.job-card {
	padding: ac(36px, 30px) ac(42px, 32px) ac(42px, 32px) ac(40px, 32px);
	background: var(--snow);
	box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.16);
	/*	perspective: 1000px;
	transform-style: preserve-3d;*/
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	&__title {
		@mixin max-line-length 4;
		transition: transform 0.25s ease;

		&:not(:last-child) {
			margin-bottom: ac(20px, 24px);
		}
	}

	&__list {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		transition: transform 0.25s ease;

		&:not(:last-child) {
			margin-bottom: ac(20px, 24px);
		}

		li {
			font-size: 18px;
			font-weight: 700;
			/*&:not(:last-child) {
				margin-bottom: ac(-6px, 2px);
			}*/


		}
	}

	&__text {
		width: 100%;
		transition: transform 0.25s ease;
		@mixin max-line-length 6;

		&:not(:last-child) {
			margin-bottom: ac(26px, 24px);
		}
	}

	&__btn {
		width: 100%;
		margin-top: auto;
		transition: transform 0.25s ease;

		.btn {
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: ac(54px, 48px);
		}
	}

	&:hover {
		/*.job-card {
			&__title {
				transform: translateZ(15px);
			}

			&__list {
				transform: translateZ(10px);
			}

			&__text {
				transform: translateZ(5px);
			}

			&__btn {
				transform: translateZ(20px);
			}
		}*/
	}

	@mixin mob-lg {
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);
	}
}
