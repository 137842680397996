.sector-card {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	&__image {
		@mixin aspect-ratio 612, 350;
		width: 100%;

		@mixin mob {
			@mixin aspect-ratio 343, 274;
		}

		img {
			transition: transform 0.25s ease;

			&.blur-up {
				transition: filter 0.4s, transform 0.25s ease;
			}
		}
	}

	&__title {
		padding-top: ac(20px, 17px);
		padding-bottom: ac(20px, 16px);
		margin-top: ac(-40px, -32px);
		position: relative;
		z-index: 1;
		width: 100%;
		max-width: calc(100% - (ac(86px, 64px)));
		padding-left: ac(100px, 40px);
		background: var(--black);
	}

	&__content {
		margin-top: ac(22px, 32px);
		width: 100%;
		padding-left: ac(100px, 46px);
		padding-right: ac(86px, 4px);

		.content-element {
			width: 100%;
			max-height: calc(ac(32px, 28px) * 6);

			.simplebar-track.simplebar-vertical {
				width: 4px;
				background: transparent;
				border-radius: 2px;
				transform: translateX(4px);

				.simplebar-scrollbar {
					background: var(--black);
					border-radius: 2px;

					&:before {
						content: none;
					}
				}
			}
		}

		.btn {
			margin-top: ac(28px, 32px);
		}

		@mixin mob-sm {
			padding-left: 20px;
		}
	}

	&:hover {
		.sector-card__image {
			img {
				transform: scale(1.05) rotate(-1.5deg);
			}
		}
	}
}

.our-sector-card {
	clip-path: inset(0 0 0 0);
	@mixin aspect-ratio-block 1, 1;
	position: relative;
	z-index: 2;

	&__image {
		position: absolute;
		z-index: -1;
		inset: 0;

		img {
			width: 100%;
			height: 100%;
			filter: grayscale(100%);
			object-fit: cover;

			&.blur-up {
				filter: blur(3px) grayscale(100%);
				transition: filter 0.4s;
			}

			&.blur-up.lazyloaded {
				filter: blur(0) grayscale(100%);
			}
		}

		&:before {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			content: "";
			background: radial-gradient(
				50% 50% at 50% 50%,
				rgba(13, 13, 13, 0.5) 0%,
				rgba(13, 13, 13, 0.7) 100%
			);
			transition: opacity 0.3s ease;
			z-index: 1;
			opacity: 1;
		}
	}

	&:hover {
		.our-sector-card__image {
			&:before {
				opacity: 0.6;
			}
		}
	}

	&__content {
		position: absolute;
		inset: 0;
		padding: ac(90px, 32px) ac(56px, 32px) ac(56px, 32px);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		z-index: 1;

		.btn {
			margin-top: ac(48px, 24px);
		}
	}

	&__title {
		margin-bottom: ac(16px, 12px);
		@mixin max-line-length 2;
		text-transform: lowercase;
	}

	&__text {
		width: 100%;
		max-height: calc(ac(32px, 28px) * 5);
		text-align: center;

		.simplebar-track.simplebar-vertical {
			width: 4px;
			background: transparent;
			border-radius: 2px;
			transform: translateX(4px);

			.simplebar-scrollbar {
				background: var(--snow);
				border-radius: 2px;

				&:before {
					content: none;
				}
			}
		}
	}

	@mixin mob {
		@mixin aspect-ratio-block 1, 1.2;
	}

	@mixin mob-sm {
		@mixin aspect-ratio-block 1, 1.5;
	}
}
