.clients-section {
	background: var(--snow);
	padding-top: ac(232px, 180px);
	padding-bottom: ac(207px, 140px);
	overflow: visible;

	.cont {
		position: relative;
	}

	.line-title {
		position: absolute;
		top: ac(-164px, -120px);
		left: 0;
		z-index: 1;
		text-align: center;
		justify-content: center;
	}

	&__wrap {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		z-index: 2;
	}

	@mixin media 380 {
		padding-bottom: 90px;
	}
}
