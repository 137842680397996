.slider-btn {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: ac(48px, 48px);
	height: ac(48px, 48px);
	border: 2px solid var(--black);
	color: var(--black);
	transition: all 0.25s ease;

	i {
		font-size: ac(16px, 16px);
		transition: all 0.25s ease;
	}

	&:hover {
		&:not(.swiper-button-disabled) {
			color: color-mod(var(--black) a(70%));
			border-color: color-mod(var(--black) a(70%));
		}

		i {
			transform: scale(1.2);
		}
	}

	&.white-btn {
		border-color: var(--white);
		color: var(--white);

		&:hover {
			&:not(.swiper-button-disabled) {
				color: color-mod(var(--white) a(70%));
				border-color: color-mod(var(--white) a(70%));
			}
		}
	}

	&.dark-fill-btn {
		border-color: var(--black);
		background: var(--black);
		color: var(--white);

		&:hover {
			background: color-mod(var(--white) a(70%));
			color: color-mod(var(--black) a(70%));
		}
	}

	&.swiper-button-disabled {
		opacity: 0.5;
		cursor: not-allowed;

		&:hover {
			opacity: 0.5;
			i {
				transform: scale(1);
			}
		}
	}
}

.slider-buttons {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 0 auto;

	.slider-btn {
		&:not(:last-child) {
			margin-right: ac(16px, 8px);
		}
	}
}

.breadcrumbs {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;

	&:not(:last-child) {
		margin-bottom: ac(28px, 18px);
	}

	li {
		display: flex;
		align-items: center;

		a,
		span {
			font-family: var(--font-main);
			font-size: ac(16px, 14px);
			line-height: 175%;
			color: var(--white);
			font-weight: 600;
		}

		i {
			font-size: 14px;
			color: var(--grey);
			line-height: 1;
			margin: 0 ac(10px, 6px);
		}

		span {
			cursor: default;
		}

		a {
			color: var(--grey);
			@mixin transition-all;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				right: 0;
				width: 0;
				height: 2px;
				background: var(--grey);
				@mixin transition-all;
			}

			&:hover {
				color: var(--blue);

				&::before {
					width: 100%;
					left: 0;
					background: var(--blue);
				}
			}
		}
	}
}

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	&__list {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	i {
		font-size: ac(48px, 38px);
	}

	&__item {
		font-family: var(--font-main);
		font-size: ac(16px, 14px);
		font-weight: 600;
		line-height: 140%;
		display: flex;
		justify-content: center;
		align-items: center;
		width: ac(48px, 38px);
		height: ac(48px, 38px);
		border: 2px solid transparent;
		transition: all 0.25s ease;

		a,
		span {
			font: inherit;
		}

		&.active {
			border-color: var(--black);
			cursor: not-allowed !important;
		}

		&:not(.active) {
			&:hover {
				border-color: var(--grey);
			}
		}

		&.with-points {
			pointer-events: none;
		}
	}

	&__item-btn {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: ac(48px, 38px);
		height: ac(48px, 38px);
		transition: all 0.25s ease;
		cursor: pointer;

		&:hover {
			color: var(--blue);
		}

		&:first-child {
			margin-right: ac(16px, 8px);
		}

		&:last-child {
			margin-left: ac(16px, 8px);
		}
	}
}
