.team-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	&__photo {
		@mixin aspect-ratio 264, 148;
		width: calc(100% - ac(32px, 24px));
		border: 2px solid var(--snow);
		border-bottom: 0;
		filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.14));
		mask-image: none;

		img {
			object-position: 50% 20%;
		}
	}

	&__content {
		border-bottom: 8px solid var(--blue);
		border-top: 8px solid var(--blue);
		border-left: 2px solid var(--blue);
		border-right: 2px solid var(--blue);
		padding: 16px ac(23px, 16px) ac(40px, 24px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		background: var(--white);
		transition: all 0.3s ease;
	}

	&__name {
		text-align: center;
		font-size: ac(24px, 20px);
		font-weight: 500;
		line-height: ac(44px, 30px);
		cursor: default;
	}

	&__position {
		font-size: ac(16px, 14px);
		font-weight: 600;
		line-height: ac(28px, 22px);
		color: var(--dark-grey);
		margin-bottom: ac(16px, 14px);
		cursor: default;
		text-align: center;
	}

	&__text {
		text-align: center;
		@mixin max-line-length 4;
		margin-bottom: 32px;
		cursor: default;

		&.smallest-type {
			p,
			li {
				font-size: 14px;
				line-height: 28px;
			}
		}
	}

	&__socials {
		margin-bottom: 20px;
	}

	&:hover {
		@mixin min-media 1026 {
			&:not(.hovered-type) {
				.team-card__content {
					box-shadow: inset -8px 0px 0px 0px var(--blue),
						inset 8px 0px 0px 0px var(--blue);
				}
			}
		}
	}

	&.dark-bg {
		.team-card__photo {
			border: none;
			box-shadow: none;
		}

		.team-card__position {
			color: var(--blue);
		}

		.team-card__content {
			background: var(--black);
			border-color: var(--white);
		}

		.team-card__socials {
			.social-btn {
				border-color: var(--white);
				color: var(--black);

				&:hover {
					background: var(--black);
					color: var(--white);
				}
			}
		}

		.btn {
			border-color: var(--white);
		}

		&:hover {
			@mixin min-media 1026 {
				&:not(.hovered-type) {
					.team-card__content {
						box-shadow: inset -8px 0px 0px 0px var(--white),
							inset 8px 0px 0px 0px var(--white);
					}
				}
			}
		}
	}

	&.hovered-type {
		.team-card__photo {
			@mixin aspect-ratio 1, 1;
			transition: all 0.3s ease;

			&:before {
				transition: all 0.3s ease;
			}

			img {
				transition: all 0.3s ease;

				&.blur-up {
					transition: all 0.3s ease;
				}
			}
		}

		.team-card__content {
			flex: 1;
		}

		.team-card__position {
			margin-bottom: 8px;
		}

		.team-card__text {
			margin-bottom: 24px;
			transition: all 0.3s ease;
			max-height: 0;
		}

		.team-card__socials {
			margin-top: auto;
			margin-bottom: 30px;
		}

		&:hover {
			@mixin min-media 1025 {
				.team-card__photo {
					@mixin aspect-ratio 264, 148;
					transition: all 0.3s ease;
				}

				.team-card__text {
					max-height: 112px;
					margin-bottom: 24px;
				}
			}
		}
	}
}
