.solutions-section {
	padding-top: ac(116px, 120px);
	padding-bottom: ac(120px, 140px);
	background: var(--snow);
	overflow: visible;

	@mixin mob {
		padding-bottom: 110px;
	}

	.cont {
		position: relative;
	}

	.line-title {
		position: absolute;
		top: ac(-60px, -84px);
		left: 0;
		z-index: 1;
		text-align: left;
		justify-content: left;
	}

	&__wrap {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		position: relative;
		z-index: 2;

		@mixin tab-md {
			flex-direction: column;
		}
	}

	&__content {
		padding-top: 110px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		width: 40.31%;

		.content-element {
			margin-bottom: ac(38px, 24px);
		}

		@mixin tab-md {
			width: 100%;

			.content-element {
				@mixin min-media 552 {
					max-width: 90%;
				}
			}

			&:not(:last-child) {
				margin-bottom: ac(32px, 28px);
			}

			&:first-child {
				padding-top: 30px;
			}
		}
	}

	&__accordion {
		width: 48.75%;

		@mixin media 1300 {
			width: 50%;
		}

		@mixin tab {
			width: 52%;
		}

		@mixin tab-md {
			width: 100%;

			&:not(:last-child) {
				margin-bottom: ac(32px, 28px);
			}
		}
	}
}

.solutions-accordion {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	&__item {
		width: 100%;
		padding: ac(16px, 14px) ac(32px, 24px);
		position: relative;
		z-index: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		&:before {
			content: "";
			z-index: -1;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 1px;
			background: var(--black);
			transition: all 0.4s ease;
		}

		&.active {
			padding: ac(32px, 24px);

			&:before {
				height: 100%;
				background: var(--blue);
			}
		}

		&:last-child {
			&:not(.active) {
				&:before {
					background-color: transparent;
				}
			}
		}
	}

	&__item-top {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	&__item-icon {
		@mixin aspect-ratio 1, 1;
		width: ac(96px, 74px);
		min-width: ac(96px, 74px);
		margin-right: 8px;

		img {
			object-fit: contain;
		}

		@mixin mob {
			width: 64px;
			min-width: 64px;
		}

		@mixin mob-sm {
			width: 60px;
			min-width: 60px;
		}
	}

	&__item-title {
		font-size: ac(22px, 18px);
		line-height: ac(36px, 26px);
		font-weight: 500;
		max-width: ac(220px, 190px);

		@mixin mob {
			padding-right: 8px;
		}

		@mixin mob-sm {
			font-size: 14px;
		}
	}

	&__btn {
		width: ac(48px, 38px);
		min-width: ac(48px, 38px);
		height: ac(48px, 38px);
		background: var(--black);
		color: var(--white);
		margin-left: auto;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 2px solid var(--black);
		transition: all 0.3s ease;
		cursor: pointer;

		i {
			position: absolute;
			font-size: ac(48px, 38px);
			transition: all 0.3s ease;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);

			&.icon-minus-btn {
				opacity: 1;
			}
			&.icon-plus-btn {
				opacity: 0;
			}
		}

		&:hover {
			background: var(--blue);
			color: var(--black);
		}

		&.active {
			border-color: var(--white);
			background-color: var(--white);
			color: var(--black);
			i {
				&.icon-minus-btn {
					opacity: 0;
				}
				&.icon-plus-btn {
					opacity: 1;
				}
			}

			&:hover {
				background: var(--black);
				color: var(--white);
			}
		}
	}

	&__item-content {
		width: 100%;
		transition: max-height 0.3s ease, margin 0.25s ease;
		max-height: 0;
		overflow: hidden;
	}

	&__item.active {
		.solutions-accordion__item-content {
			margin-top: ac(16px, 6px);
			max-height: 300px;
		}
	}
}
