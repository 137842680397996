.stages-section {
	background: var(--gradient-main);
	padding-top: ac(318px, 200px);
	padding-bottom: ac(190px, 140px);
	margin-bottom: ac(74px, 24px);
	position: relative;
	z-index: 10;
	clip-path: polygon(0 0, 100% ac(127px, 69px), 100% 100%, 0 100%);
	transform: translateY(2px);

	@mixin mob-xl {
		background: var(--blue);
	}

	@mixin min-media 1450 {
		clip-path: polygon(0 0, 100% 8.82vw, 100% 100%, 0 100%);
	}

	.cont {
		position: relative;
	}

	.line-title {
		position: absolute;
		top: ac(-120px, -90px);
		left: 0;
		z-index: 1;
		text-align: left;
		justify-content: left;

		@mixin min-media 652 {
			text-align: center;
			justify-content: center;
		}
	}

	&__wrap {
		/*display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));*/
		grid-column-gap: ac(30px, 16px);
		grid-row-gap: ac(30px, 16px);
		position: relative;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: stretch;
		width: 100%;

		.stage-card {
			width: 30%;
		}

		@mixin tab {
			.stage-card {
				width: 40%;
			}
		}

		@mixin tab-md {
			grid-template-columns: repeat(2, minmax(0, 1fr));

			.stage-card {
				/*&:nth-child(3) {
					&:last-child {
						grid-column: 1/-1;
					}
				}*/
				width: 44%;
			}
		}

		@mixin mob-xl {
			/*grid-template-columns: repeat(1, minmax(0, 1fr));*/
			grid-row-gap: 20px;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;

			.stage-card {
				width: 100%;
			}
		}
	}

	@mixin tab-sm {
		clip-path: polygon(0 0, 100% 8.98vw, 100% 100%, 0 100%);
	}
}
