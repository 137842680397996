.first-section {
	position: relative;
	z-index: 10;
	overflow: hidden;

	&__bg {
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		z-index: -1;

		&:before {
			position: absolute;
			content: "";
			inset: 0;
			background: linear-gradient(
				190deg,
				rgba(13, 13, 13, 0.71) 0%,
				color-mod(var(--black) a(0%)) 100%
			);
			z-index: 1;

			@mixin mob-lg {
				background: linear-gradient(
					180deg,
					rgba(13, 13, 13, 0) 31.84%,
					#0d0d0d 91.8%
				);
			}
		}

		&:after {
			position: absolute;
			bottom: 0;
			content: "";
			left: 0;
			right: 0;
			height: 57.11%;
			z-index: 2;
			background: linear-gradient(
				190deg,
				color-mod(var(--black) a(0%)) 0%,
				rgba(13, 13, 13, 1) 100%
			);
			clip-path: polygon(0 38%, 100% 0, 100% 100%, 0 100%);

			@mixin mob-lg {
				display: none;
			}
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			filter: grayscale(100%);

			&.blur-up {
				filter: blur(3px) grayscale(100%);
				transition: filter 0.4s;
			}

			&.blur-up.lazyloaded {
				filter: blur(0) grayscale(100%);
			}
		}
	}

	&__decor {
		@mixin aspect-ratio 644, 700;
		position: absolute;
		overflow: visible;
		z-index: -1;
		right: 0;
		bottom: ac(80px, 103px);
		min-width: 386px;
		width: 71.56vh;
		max-width: 44.72vw;
		mask-image: none;

		img {
			object-fit: contain;

			&.blur-up {
				transition: filter 0.4s, transform 0.1s ease;
			}
		}

		@mixin min-media 1441 {
			max-width: 644px;
		}

		@mixin tab-sm {
			right: -80px;
		}

		@mixin mob-lg {
			right: -120px;
		}

		@mixin mob {
			right: -196px;
		}
	}

	&__wrap {
		padding-top: ac(120px, 100px);
		padding-bottom: ac(136px, 64px);
		min-height: 600px;
		height: 100vh;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		z-index: 10;

		.scroll-toggle {
			position: absolute;
			right: ac(0px, -10px);
			bottom: ac(77px, 50px);

			@mixin mob-xl {
				display: none;
			}
		}

		.title {
			margin-top: auto;
			max-width: ac(870px, 480px);
			text-align: center;
			user-select: none;
			cursor: auto;
		}

		.buttons {
			margin-top: ac(42px, 40px);

			@mixin mob-lg {
				width: 100%;
				flex-direction: column;

				.btn {
					width: 100%;
					text-align: center;

					&:not(:last-child) {
						margin-bottom: 16px;
						margin-right: 0;
					}
				}
			}
		}

		@mixin tab {
			@mixin min-media 651 {
				padding-bottom: 320px;

				.first-section__decor {
					bottom: 240px;
				}
			}
		}
	}

	&.not-found-page {
		.first-section__bg {
			&:before {
				background: radial-gradient(
						69.79% 69.79% at 50% 30.21%,
						rgba(13, 13, 13, 0.5) 0%,
						#0d0d0d 100%
					),
					#56def6;
			}
		}

		.first-section__wrap {
			padding-bottom: ac(136px, 200px);

			@mixin mob-xl {
				padding-bottom: 140px;
			}
		}
	}
}

.first-internal-section {
	position: relative;
	z-index: 10;
	overflow: visible;
	clip-path: inset(0 0 -500px 0);

	&__bg {
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		overflow: hidden;

		&:before {
			position: absolute;
			content: "";
			inset: 0;
			background: radial-gradient(
				69.79% 69.79% at 50% 30.21%,
				rgba(13, 13, 13, 0.5) 0%,
				rgba(13, 13, 13, 0.5) 45.62%,
				#0d0d0d 95%,
				#0d0d0d 100%
			);
			background-blend-mode: normal, luminosity;
			z-index: 1;
		}

		&:after {
			content: "";
			width: ac(1199px, 840px);
			height: ac(1199px, 840px);
			position: absolute;
			background-image: url("../images/decors/circle-first.svg");
			background-size: contain;
			background-repeat: no-repeat;
			right: 0;
			top: 0;
			transform: translateX(43.29%) translateY(-18.18%);
			z-index: 2;
			mix-blend-mode: overlay;

			@mixin desk-sm {
				transform: translateX(46.29%) translateY(-18.18%);
			}

			@mixin media 1100 {
				transform: translateX(48.29%) translateY(-18.18%);
			}

			@mixin tab {
				transform: translateX(56%) translateY(-18.18%);
			}

			@mixin media 970 {
				transform: translateX(60%) translateY(-18.18%);
			}

			@mixin tab-md {
				transform: translateX(65%) translateY(-18.18%);
			}

			@mixin tab-sm {
				width: 900px;
				height: 900px;
				transform: translateX(700px) translateY(-18.18%);
			}

			@mixin min-media 1520 {
				transform: translateX(40%) translateY(-18.18%);
				width: 1250px;
				height: 1250px;
			}

			@mixin min-media 1650 {
				transform: translateX(35%) translateY(-18.18%);
			}

			@mixin min-media 1840 {
				transform: translateX(30%) translateY(-18.18%);
			}
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			filter: grayscale(100%);

			&.blur-up {
				filter: blur(3px) grayscale(100%);
				transition: filter 0.4s;
			}

			&.blur-up.lazyloaded {
				filter: blur(0) grayscale(100%);
			}

			@mixin tab {
				object-position: 0% 50%;
			}

			@mixin mob-xl {
				object-position: 50% 50%;
			}
		}

		&.who-we-are-img {
			img {
				object-position: 50% 65%;

				@mixin tab {
					object-position: 0% 65%;
				}

				@mixin mob-xl {
					object-position: 50% 65%;
				}
			}
		}

		&.meet-team-img {
			img {
				object-position: 50% 100%;

				@mixin tab {
					object-position: 0% 100%;
				}

				@mixin mob-xl {
					object-position: 50% 100%;
				}
			}
		}

		&.recruitment-img {
			&:after {
				content: none;
			}
		}

		&.sectors-img {
			img {
				object-position: 60% 40%;

				/*@mixin tab {
					object-position: 50% 40%;
				}

				@mixin mob-xl {
					object-position: 50% 40%;
				}*/
			}
		}

		&.job-board-page {
			img {
				object-position: 50% 100%;

				@mixin media 1450 {
					@mixin min-media 1400 {
						transform: scale(1.05) translateY(-20px);
					}
				}

				@mixin tab {
					object-position: 0% 100%;
				}

				@mixin mob-xl {
					object-position: 50% 100%;
				}
			}
		}
	}

	&__wrap {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		z-index: 10;
		padding-top: ac(200px, 120px);
		padding-bottom: ac(138px, 64px);
		min-height: ac(699px, 550px);

		.scroll-toggle {
			position: absolute;
			right: ac(0px, -10px);
			bottom: ac(52px, 50px);

			@mixin mob-xl {
				display: none;
			}
		}

		@mixin tab {
			@mixin min-media 651 {
				padding-bottom: 140px;
			}
		}

		@mixin min-media 1600 {
			min-height: 750px;
		}
	}

	&.meet-team-page {
		.first-internal-section__wrap {
			min-height: ac(723px, 550px);

			.scroll-toggle {
				bottom: ac(74px, 50px);
			}
		}
	}

	&__title {
		max-width: ac(700px, 480px);
		text-align: left;
		user-select: none;
		cursor: auto;
		text-transform: lowercase;

		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}

	&__text {
		max-width: ac(550px, 380px);

		&:not(:last-child) {
			margin-bottom: ac(48px, 24px);
		}
	}

	&__buttons {
		.btn {
			&:not(:last-child) {
				margin-right: ac(30px, 16px);
			}
		}
		@mixin mob-lg {
			width: 100%;
			flex-direction: column;

			.btn {
				width: 100%;
				text-align: center;

				&:not(:last-child) {
					margin-bottom: 16px;
					margin-right: 0;
				}
			}
		}
	}

	.first-bottom-decor {
		display: block;
		width: 100%;
		@mixin aspect-ratio-block 1440, 127;
		position: absolute;
		overflow: visible;
		z-index: 1;
		bottom: 0;
		left: 0;
		transform: translateY(100%) scale(1.03);
		background: var(--black);

		&:after {
			position: absolute;
			inset: 0;
			content: "";
		}

		&:after {
			background: var(--blue);
			/*clip-path: polygon(100% 0, 0% 100%, 100% 100%);*/
			clip-path: polygon(0 0, 0% 100%, 100% 100%);
			transform: scale(1.02);
		}
	}

	&.with-bottom-decor {
		background: var(--black);
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - ac(127px, 60px)));

		@mixin min-media 1450 {
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 8.82vw));
		}

		@mixin tab-sm {
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 8.98vw));
		}

		.first-internal-section__wrap {
			padding-bottom: ac(264px, 128px);
			min-height: ac(826px, 620px);

			@mixin tab {
				@mixin min-media 651 {
					padding-bottom: 260px;
				}
			}

			@mixin min-media 1600 {
				min-height: 800px;
			}

			.scroll-toggle {
				bottom: ac(178px, 90px);
			}
		}

		&.contact-page {
			.first-internal-section__wrap {
				min-height: ac(770px, 620px);

				@mixin tab {
					@mixin min-media 651 {
						padding-bottom: 260px;
					}
				}

				@mixin min-media 1600 {
					min-height: 800px;
				}

				.scroll-toggle {
					bottom: ac(122px, 90px);
				}
			}
		}

		&.job-board-type {
			clip-path: none;

			.first-bottom-decor {
				background: transparent;
				transform: scale(1.04);

				&:after {
					background: var(--snow);
				}
			}
		}

		&.job-view-page {
			.first-internal-section__wrap {
				min-height: ac(763px, 620px);

				@mixin tab {
					@mixin min-media 651 {
						padding-bottom: 160px;
					}
				}

				@mixin min-media 1600 {
					min-height: 800px;
				}

				.scroll-toggle {
					bottom: ac(122px, 90px);
				}
			}

			&.policy-page {
				.first-internal-section__wrap {
					min-height: ac(560px, 420px);
					padding-bottom: ac(200px, 120px);

					@mixin tab {
						@mixin min-media 651 {
							padding-bottom: 120px;
						}
					}

					@mixin min-media 1600 {
						min-height: 580px;
					}

					.scroll-toggle {
						bottom: ac(122px, 90px);
					}
				}
			}
		}

		&.contact-page {
			@mixin mob-lg {
				.first-internal-section__bg::after {
					opacity: .5;
				}
			}
		}

		.first-internal-section__bg {
			/*img {
				height: calc(100% - ac(127px, 60px));
			}*/

			&:before {
				background: radial-gradient(
					69.79% 69.79% at 50% 30.21%,
					rgba(13, 13, 13, 0.5) 0%,
					rgba(13, 13, 13, 0.5) 30%,
					#0d0d0d 95%,
					#0d0d0d 100%
				);
			}

			&.with-job-view {
				&:before {
					background: radial-gradient(
							69.79% 69.79% at 50% 30.21%,
							rgba(13, 13, 13, 0.5) 0%,
							#0d0d0d 100%
						),
						#56def6;
				}

				&:after {
					content: none;
				}
			}
		}

		.first-internal-section__bg-with-bottom-decor {
			position: absolute;
			inset: 0;
			background: linear-gradient(
				190deg,
				color-mod(var(--black) a(0%)) 0%,
				color-mod(var(--black) a(0%)) 70%,
				color-mod(var(--black) a(100%)) 85%,
				color-mod(var(--black) a(100%)) 100%
			);
			z-index: 1;
		}

		+ .about-section {
			margin-top: ac(-128px, -62px);
			overflow: visible;

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 200px;
				background: var(--blue);
				transform: translateY(-98%);
				content: "";
			}
		}
	}

	&__links {
		padding-top: ac(32px, 20px);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		a {
			color: var(--blue);
			font-size: ac(22px, 18px);
			font-weight: 400;
			line-height: ac(27px, 22px);
			transition: all 0.25s ease;

			&:not(:last-child) {
				margin-bottom: ac(16px, 10px);
			}

			&:hover {
				color: var(--snow);
			}
		}
	}

	&__form {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		max-width: ac(764px, 620px);
		margin-bottom: -65px;

		.btn {
			margin-top: 24px;
			margin-left: auto;
			min-height: ac(56px, 48px);
			min-width: ac(184px, 178px);
		}

		.default-input {
			input {
				&::placeholder {
					color: var(--white);
				}
			}
		}

		@mixin mob-lg {
			margin-bottom: -20px;

			.btn {
				width: 100%;
			}
		}
	}

	.btn-filters {
		margin-top: 24px;
		margin-left: auto;
		min-height: ac(56px, 48px);
		min-width: ac(184px, 178px);
		display: flex;
		justify-content: center;
		align-items: center;

		@mixin min-media 770 {
			display: none;
		}

		@mixin mob-lg {
			width: 100%;
		}
	}

	&__form-title {
		margin-top: ac(64px, 32px);
		font-size: ac(34px, 24px);
		font-weight: 700;
		line-height: ac(48px, 30px);

		&:not(:last-child) {
			margin-bottom: ac(32px, 24px);
		}
	}

	&__form-grid {
		width: 100%;
		display: grid;
		/*grid-template-columns: 1fr 154px 1fr;*/
		grid-template-columns: repeat(3, minmax(0, 1fr));
		grid-column-gap: 16px;
		grid-row-gap: 16px;

		.default-input,
		.default-select {
			&.with-full {
				grid-column: span 2 / span 2;

				@mixin mob-lg {
					grid-column: 1 / -1;
				}
			}
		}

		@mixin mob-xl {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@mixin mob-lg {
			grid-template-columns: repeat(1, minmax(0, 1fr));
		}
	}

	&__additional {
		padding-top: ac(32px, 40px);
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;

		&:not(:last-child) {
			padding-bottom: ac(30px, 14px);
		}

		+ .first-internal-section__buttons {
			margin-bottom: ac(-20px, 10px);
		}
	}

	&__additional-item {
		margin-bottom: 10px;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		&:not(:last-child) {
			margin-right: ac(32px, 12px);
		}

		i {
			font-size: ac(40px, 34px);
			margin-right: 4px;
			color: var(--white);
		}

		span {
			font-family: var(--font-main);
			font-size: ac(20px, 16px);
			font-weight: 500;
			line-height: ac(36px, 28px);
			color: var(--white);
		}
	}
}
