.jobs-slider {
	width: 100%;
	max-width: ac(930px, 640px);
	margin-left: 0;
	overflow: visible;
	opacity: 0;
	transition: opacity 0.1s ease;
	user-select: none;

	&.swiper-initialized {
		opacity: 1;
	}

	.swiper-slide {
		height: auto;
	}
}

.jobs-slider-buttons {
	justify-content: center;
	.slider-btn {
		margin-top: ac(48px, 32px);

		&.swiper-button-lock {
			display: none;
		}
	}
}
