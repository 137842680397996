.teams-slider {
	.swiper-slide {
		height: auto;
	}

	.team-card {
		&.hovered-type {
			@mixin tab {
				&.swiper-slide-active {
					.team-card__photo {
						@mixin aspect-ratio 264, 148;
						transition: all 0.3s ease;
					}

					.team-card__text {
						max-height: 112px;
						margin-bottom: 24px;
					}
				}

				&.swiper-slide-next {
					@mixin min-media 422 {
						.team-card__photo {
							@mixin aspect-ratio 264, 148;
							transition: all 0.3s ease;
						}

						.team-card__text {
							max-height: 112px;
							margin-bottom: 24px;
						}
					}

					+ .team-card {
						@mixin min-media 768 {
							.team-card__photo {
								@mixin aspect-ratio 264, 148;
								transition: all 0.3s ease;
							}

							.team-card__text {
								max-height: 112px;
								margin-bottom: 24px;
							}
						}
					}
				}
			}
		}
	}

	@mixin mob-lg {
		overflow: visible;
	}
}
