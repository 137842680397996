.recruiter-section {
	padding-bottom: 54px;
	z-index: 10;
	background: var(--black);
	overflow: visible;

	@mixin mob-lg {
		padding-top: 48px;
	}

	.line-title {
		position: absolute;
		top: ac(164px, 25px);
		left: 0;
		z-index: 1;
		padding-left: 16px;
		padding-right: 16px;

		h2 {
			max-width: ac(690px, 500px);
			transform: translateX(ac(-240px, -50px));

			@mixin tab-md {
				max-width: 100%;
				transform: none;
			}
		}

		@mixin tab-md {
			z-index: 20;
		}
	}

	&__decor {
		top: ac(-130px, -64px);
		right: -124px;
		@mixin aspect-ratio 644, 700;
		position: absolute;
		width: ac(644px, 300px);
		z-index: 2;
		overflow: visible;
		mask-image: none;
		img {
			object-fit: contain;

			&.blur-up {
				transition: filter 0.4s, transform 0.1s ease;
			}
		}

		@mixin tab-sm {
			right: -170px;
		}

		@mixin mob-xl {
			display: none;
		}
	}

	&__wrap {
		padding-top: ac(284px, 200px);
		position: relative;
		z-index: 2;
		width: 100%;
		max-width: ac(958px, 600px);
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		@mixin mob-lg {
			padding-top: 128px;
		}
	}

	.content-element {
		width: 100%;
		max-width: ac(486px, 350px);
		margin-right: auto;

		@mixin mob-lg {
			max-width: 100%;
		}

		&:not(:last-child) {
			margin-bottom: ac(34px, 40px);
		}
	}

	.subtitle {
		width: 100%;
		max-width: ac(580px, 400px);
		margin-left: ac(162px, 0px);

		@mixin mob-lg {
			max-width: 100%;
		}

		&:not(:last-child) {
			margin-bottom: ac(28px, 24px);
		}
	}

	.buttons {
		margin-left: ac(162px, 0px);
		@mixin mob-lg {
			width: 100%;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;

			.btn {
				width: 100%;
				text-align: center;
				&:not(:last-child) {
					margin-right: 0;
					margin-bottom: 16px;
				}
			}
		}
	}
}
