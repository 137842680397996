.stage-card {
	background: var(--white);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding: ac(40px, 18px) ac(24px, 18px) ac(40px, 18px) ac(40px, 18px);
	min-height: ac(296px, 200px);

	&__top {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 24px;
	}

	&__num {
		border-width: 4px 1.5px;
		border-style: solid;
		border-color: var(--black);
		width: ac(64px, 48px);
		height: ac(64px, 48px);
		min-width: ac(64px, 48px);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: ac(34px, 24px);
		font-weight: 700;
		line-height: ac(48px, 30px);
		text-align: center;
		font-family: var(--font-main);
		margin-right: ac(24px, 12px);
	}

	&__title {
		font-size: ac(24px, 18px);
		font-weight: 500;
		line-height: ac(44px, 24px);
	}

	&__text {
		@mixin tab-md {
			br {
				display: none;
			}
		}
	}
}
