.text-video-section {
	background: var(--snow);
	padding-top: ac(232px, 120px);
	padding-bottom: ac(128px, 64px);
	overflow: hidden;

	&.with-why-us-type {
		overflow: visible;
		padding-top: ac(164px, 120px);
		padding-bottom: ac(80px, 64px);
		.line-title {
			top: ac(-88px, -84px);
			padding-left: 59.69%;
		}
	}

	.cont {
		position: relative;
	}

	.line-title {
		position: absolute;
		top: ac(-64px, -84px);
		left: 0;
		z-index: 1;
		text-align: left;
		justify-content: left;
	}

	&__decor {
		@mixin aspect-ratio 991, 1076;
		width: ac(656px, 400px);
		position: absolute;
		top: ac(-232px, -120px);
		right: 0;
		transform: translateX(ac(100px, 197px)) translateY(ac(27px, -40px));
		overflow: visible;
		mask-image: none;
		img {
			object-fit: contain;

			&.blur-up {
				transition: filter 0.4s, transform 0.1s ease;
			}
		}

		@mixin tab-md {
			top: 50%;
			transform: translateX(ac(100px, 197px)) translateY(-50%);
		}
	}

	&__wrap {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		z-index: 2;

		@mixin tab-md {
			flex-direction: column;
		}
	}

	&__content {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		width: 40.31%;

		.content-element {
			margin-bottom: ac(38px, 24px);
		}

		@mixin tab-md {
			width: 100%;

			.content-element {
				@mixin min-media 552 {
					max-width: 90%;
				}
			}

			&:not(:last-child) {
				margin-bottom: ac(32px, 28px);
			}

			&:first-child {
				padding-top: 30px;
			}
		}
	}

	&__video {
		width: 55.94%;

		@mixin tab-md {
			width: 100%;

			&:not(:last-child) {
				margin-bottom: ac(32px, 28px);
			}
		}

		.plyr__video-wrapper {
			z-index: 0;
		}
	}

	&__image {
		@mixin aspect-ratio 716, 404;

		width: 55.94%;

		@mixin tab-md {
			width: 100%;

			&:not(:last-child) {
				margin-bottom: ac(32px, 28px);
			}
		}
	}
}
