.about-section {
	padding-top: ac(126px, 68px);
	background: var(--blue);
	padding-bottom: ac(80px, 40px);

	@mixin min-media 1850 {
		padding-top: 7.29vw;
	}

	&__wrap {
		padding-top: ac(66px, 40px);
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.line-title {
		width: auto;
		padding-left: 20px;
		padding-right: 20px;
		min-width: ac(600px, 420px);

		&:before,
		&:after {
			content: none;
		}

		@mixin mob-xl {
			min-width: auto;
			width: 100%;

			&:before,
			&:after {
				content: "";
			}
		}
	}

	&__text {
		margin-top: ac(66px, 40px);
		max-width: ac(844px, 640px);
	}
}
