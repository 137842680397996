.areas-section {
	padding-top: ac(230px, 160px);
	padding-bottom: ac(185px, 120px);
	overflow: hidden;
	background: var(--black);
	clip-path: polygon(
		0 -10px,
		100% 0,
		100% 100%,
		0 calc(100% - ac(127px, 60px))
	);

	@mixin min-media 1450 {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 8.82vw));
	}

	@mixin tab-sm {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 8.98vw));
	}

	.cont {
		position: relative;
	}

	.line-title {
		position: absolute;
		top: ac(-200px, -130px);
		left: 0;
		z-index: 1;
		text-align: center;
		justify-content: center;
		background: var(--snow);

		h2 {
			color: var(--black);
		}

		&__bg {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%) translateY(-99%);
			height: 300px;
			background: var(--snow);
			width: 200%;
		}
	}
}

.areas-container {
	padding-top: 16px;
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	.areas-content {
		width: 52.27%;
		position: relative;

		@mixin desk-sm {
			width: 52.5%;
		}
	}

	.areas-tab {
		width: 40.23%;

		@mixin desk-sm {
			width: 44.5%;
		}

		@mixin mob-xl {
			width: 100%;
		}
	}
}

.areas-content {
	position: relative;
	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		transform: translateX(-104%);
		transition: transform 0.4s ease, opacity 0.4s ease;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		pointer-events: none;

		&.active {
			transform: translateX(0);
			position: relative;
			pointer-events: auto;
			opacity: 1;
		}
	}

	&__image {
		@mixin aspect-ratio 589, 280;
		width: calc(100% - ac(80px, 40px));
		margin-bottom: ac(-50px, -20px);
	}

	&__item-content {
		width: 100%;
		border: 1.5px solid var(--white);
		padding: ac(32px, 20px) ac(48px, 22px) ac(48px, 22px);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		z-index: 2;
		background: var(--black);
	}

	&__title {
		font-size: ac(32px, 24px);
		font-weight: 500;
		line-height: ac(48px, 30px);
		margin-bottom: ac(24px, 20px);
		max-width: calc(100% - 42px);
	}

	&__btn {
		top: ac(32px, 20px);
		right: ac(48px, 22px);
		position: absolute;
		width: ac(48px, 38px);
		min-width: ac(48px, 38px);
		height: ac(48px, 38px);
		background: var(--black);
		color: var(--white);
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1.5px solid var(--white);
		transition: all 0.3s ease;
		cursor: pointer;

		i {
			position: absolute;
			font-size: ac(48px, 38px);
			transition: all 0.3s ease;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);

			&.icon-minus-btn {
				opacity: 1;
			}
			&.icon-plus-btn {
				opacity: 0;
			}
		}

		&:hover {
			background: var(--snow);
			color: var(--black);
		}

		&.active {
			i {
				&.icon-minus-btn {
					opacity: 0;
				}
				&.icon-plus-btn {
					opacity: 1;
				}
			}
		}
	}

	&__text {
		width: 100%;
		max-height: calc(ac(28px, 24px) * 12);

		&.smallest-type {
			p,
			li {
				line-height: ac(28px, 24px);

				&:not(:last-child) {
					margin-bottom: ac(28px, 24px);
				}
			}
		}

		.simplebar-track.simplebar-vertical {
			width: 4px;
			background: transparent;
			border-radius: 2px;
			transform: translateX(6px);

			.simplebar-scrollbar {
				background: var(--snow);
				border-radius: 2px;

				&:before {
					content: none;
				}
			}
		}
	}

	.btn {
		margin-top: ac(38px, 24px);
	}

	@mixin mob-xl {
		display: none;
	}
}

.areas-tab {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	&__item {
		width: 100%;
		overflow: hidden;
		transition: max-height 0.3s ease, margin-bottom 0.3s ease;
		transition-delay: 0.35s;
		max-height: 200px;

		&:not(:last-child) {
			margin-bottom: ac(40px, 24px);
		}

		&.active {
			@mixin min-media 652 {
				max-height: 0;

				&:not(:last-child) {
					margin-bottom: 0;
				}

				.areas-tab__item-content {
					transform: translateX(100%);
					opacity: 0;
				}
			}

			@mixin mob-xl {
				.areas-tab__text-content {
					opacity: 1;
					max-height: 300px;
					margin-top: 20px;
				}
			}
		}

		@mixin mob-xl {
			max-height: none;
		}
	}

	&__item-content {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		transition: transform 0.3s ease, opacity 0.3s ease;
		transform: translateX(0);
		opacity: 1;
	}

	&__image {
		@mixin aspect-ratio 1, 1;
		width: ac(144px, 100px);
		border: 1.5px solid var(--snow);

		@mixin mob-xl {
			width: 120px;
		}
	}

	&__title-btn-container {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding-left: ac(32px, 10px);
		padding-right: 10px;
		padding-top: ac(28px, 20px);
		padding-bottom: ac(18px, 16px);
		border-bottom: 1.5px solid var(--snow);

		@mixin mob-sm {
			padding-left: 0;
		}
	}

	&__title {
		font-size: ac(22px, 16px);
		font-weight: 500;
		line-height: ac(36px, 22px);
		min-height: calc(ac(36px, 22px) * 2);
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__btn {
		width: ac(48px, 38px);
		min-width: ac(48px, 38px);
		height: ac(48px, 38px);
		background: var(--black);
		color: var(--white);
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1.5px solid var(--white);
		transition: all 0.3s ease;
		cursor: pointer;
		position: relative;
		margin-right: ac(24px, 12px);

		i {
			position: absolute;
			font-size: ac(48px, 38px);
			transition: all 0.3s ease;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);

			&.icon-minus-btn {
				opacity: 1;
			}
			&.icon-plus-btn {
				opacity: 0;
			}
		}

		&:hover {
			background: var(--snow);
			color: var(--black);
		}

		&.active {
			i {
				&.icon-minus-btn {
					opacity: 0;
				}
				&.icon-plus-btn {
					opacity: 1;
				}
			}
		}
	}

	&__text-content {
		display: none;

		@mixin mob-xl {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			width: 100%;
			max-height: 0;
			padding-left: 10px;
			padding-right: 10px;
			transition: max-height 0.4s ease, margin-top 0.4s ease, opacity 0.3s ease;
			opacity: 0;
			margin-top: 0;

			.btn {
				margin-top: 20px;
			}
		}

		@mixin mob-sm {
			padding-left: 0;
		}
	}

	&__text {
		width: 100%;
		max-height: calc(ac(28px, 22px) * 10);

		.simplebar-track.simplebar-vertical {
			width: 2px;
			background: transparent;
			border-radius: 2px;
			transform: translateX(5px);

			.simplebar-scrollbar {
				background: var(--snow);
				border-radius: 2px;

				&:before {
					content: none;
				}
			}
		}

		&.smallest-type {
			p,
			li {
				&:not(:last-child) {
					line-height: ac(28px, 22px);
				}
			}
		}
	}
}
