.sectors-slider {
	width: 100%;
	overflow: visible;

	&:not(.swiper-initialized) {
		.swiper-wrapper {
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			grid-column-gap: ac(56px, 20px);
			grid-row-gap: 54px;

			@mixin mob-xl {
				grid-template-columns: repeat(1, minmax(0, 1fr));
			}
		}

		.sector-card__content {
			@mixin tab-sm {
				@mixin min-media 652 {
					padding-left: 8px;
				}
			}
		}
	}
}

.our-sectors-slider {
	&:not(.swiper-initialized) {
		.swiper-wrapper {
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			grid-column-gap: ac(32px, 20px);
			grid-row-gap: ac(32px, 20px);

			@mixin tab-sm {
				grid-template-columns: repeat(1, minmax(0, 1fr));
			}
		}

		.our-sectors-slider-buttons {
			display: none;
		}
	}
}

.our-sectors-slider-buttons {
	justify-content: center;
	.slider-btn {
		margin-top: 32px;

		&.swiper-button-lock {
			display: none;
		}
	}
}
