.project-popup {
	padding: 0;
	max-width: 780px;
	width: 100%;
	scrollbar-width: thin;
	scrollbar-color: var(--blue) var(--light-grey);
	max-height: 92vh;
	overflow: hidden;
	background: transparent;

	.popup__title {
		font-family: var(--font-main);
		font-size: ac(34px, 24px);
		font-weight: 700;
		line-height: ac(44px, 30px);
		color: var(--black);
		padding-right: ac(78px, 60px);
		padding-bottom: ac(24px, 20px);
		margin-bottom: 0;
		width: calc(100% - 30px);
		background: var(--white);
		text-transform: lowercase;
		z-index: 2;
		display: flex;
		align-items: center;
		padding-top: ac(24px, 20px);
		padding-left: ac(40px, 24px);

		@mixin mob {
			padding-right: 40px;
		}
	}

	.popup__btn-close {
		top: ac(24px, 20px);
		right: ac(40px, 24px);
		z-index: 10;
		width: ac(48px, 34px);
		height: ac(48px, 34px);
		border-radius: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid var(--black);
		transition: background-color 0.25s ease, border-color 0.25s ease;

		&:before {
			font-family: "icomoon" !important;
			speak: never;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "\e912";
			font-size: ac(48px, 34px);
			color: var(--black);
			transition: color 0.25s ease;
		}

		.cross-clip {
			display: none;
		}

		&:hover {
			background-color: var(--blue);
		}

		@mixin mob {
			top: 4px;
			right: 4px;
		}
	}

	.popup__container {
		position: relative;
		background: var(--white);
		border-left: ac(8px, 6px) solid var(--blue);
	}

	.popup__content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;

		&:before {
			position: absolute;
			content: "";
			bottom: 0;
			height: ac(40px, 20px);
			left: 0;
			width: calc(100% - 20px);
			background: var(--white);
			z-index: 10;

			@mixin tab-sm {
				left: 30px;
				width: calc(100% - 60px);
			}
		}
	}

	&__content {
		width: 100%;
		padding: ac(32px, 20px) ac(40px, 24px) ac(56px, 40px);
		/*scrollbar-width: thin;
        scrollbar-color: var(--blue) var(--light-grey);*/
		max-height: 66vh;
		/*overflow-y: auto;*/
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		.simplebar-track.simplebar-vertical {
			width: ac(20px, 18px);
			background: var(--grey);
			border-radius: 0;

			.simplebar-scrollbar {
				background: var(--blue);
				border-radius: 0;
				left: 4px;
				right: 4px;
				box-shadow: inset 0 4px 0 0 var(--grey), inset 0 -4px 0 0 var(--grey);

				&:before {
					content: none;
				}
			}
		}

		.form {
			&__grid {
				display: grid;
				grid-template-columns: repeat(2, minmax(0, 1fr));
				grid-column-gap: ac(24px, 12px);
				grid-row-gap: ac(24px, 12px);

				@mixin mob-xl {
					grid-template-columns: repeat(1, minmax(0, 1fr));
				}
			}

			&__bottom {
				margin-top: ac(20px, 16px);
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;

				.checkbox {
					width: 100%;

					.checkbox-text a {
						color: var(--dark-grey);

						&:hover {
							color: var(--blue);
						}
					}
				}

				.btn {
					margin-top: 20px;
					margin-left: auto;
					margin-right: auto;
				}
			}

			.default-input {
				&.with-full {
					grid-column: 1/-1;
				}

				.default-popup-file-input {
					padding-bottom: 0;
				}
			}
		}
	}

	@mixin tab-sm {
		width: 100%;
		max-width: 91vw;
	}
}
