.testimonial-card {
	background: var(--blue);
	padding: ac(109px, 40px) ac(78px, 24px) ac(85px, 32px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	transition: border-radius 0.25s ease, box-shadow 0.25s ease;

	&__content {
		width: 100%;
		text-align: center;

		&:not(:last-child) {
			margin-bottom: ac(48px, 30px);
		}

		p,
		li {
			font-size: ac(18px, 16px);
			font-weight: 600;
			line-height: ac(36px, 26px);
			font-style: italic;

			@mixin mob-lg {
				padding-right: 2px;
			}
		}

		.blockquote-content__icon {
			font-size: 19px;
			&:first-child {
				transform: none;
				margin-right: ac(16px, 2px);
				margin-bottom: auto;

				@mixin mob {
					transform: none;
				}
			}

			&:last-child {
				margin-top: auto;
				transform: none;
				margin-left: 6px;

				@mixin mob {
					transform: none;
				}
			}
		}

		/*max-height: calc(ac(34px, 24px) * 8.01);

		&:not(:last-child) {
			margin-bottom: ac(20px, 16px);
		}

		.simplebar-track.simplebar-vertical {
			width: 2px;
			background: transparent;
			border-radius: 2px;
			transform: translateX(4px);

			.simplebar-scrollbar {
				background: var(--blue);
				border-radius: 2px;

				&:before {
					content: none;
				}
			}
		}*/
	}

	&__name {
		font-size: ac(24px, 18px);
		font-weight: 500;
		line-height: ac(44px, 26px);
		margin-bottom: 8px;
		text-align: center;
	}

	&__position {
		font-size: ac(20px, 16px);
		font-weight: 500;
		line-height: ac(36px, 20px);
		text-align: center;
		margin-bottom: 0;
	}

	@mixin mob {
		padding-left: 10px;
		padding-right: 10px;
	}

	&__text {
		flex: 1;
		max-height: calc(ac(36px, 26px) * 6);

		.simplebar-track.simplebar-vertical {
			width: 4px;
			background: transparent;
			border-radius: 2px;
			transform: translateX(4px);

			.simplebar-scrollbar {
				background: var(--black);
				border-radius: 2px;

				&:before {
					content: none;
				}
			}
		}
	}
}
