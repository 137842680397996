.contact-section {
	padding-top: ac(266px, 200px);
	padding-bottom: ac(208px, 120px);
	background: var(--gradient-main);
	z-index: 9;
	overflow: visible;

	&.internal-contact-section {
		padding-top: ac(88px, 120px);

		.contact-section__decor {
			@mixin min-media 652 {
				width: ac(1004px, 883px);
				transform: translateX(ac(-121px, -356px)) translateY(-50%);
			}
		}
	}

	&:before {
		content: "";
		background: var(--black);
		position: absolute;
		bottom: 0;
		height: 200px;
		left: 0;
		right: 0;
		transform: translateY(99%);
		z-index: -1;
	}

	&__decor {
		width: ac(1127px, 883px);
		@mixin aspect-ratio 1127, 1228;
		overflow: visible;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateX(ac(-280px, -356px)) translateY(-54.5%);
		mask-image: none;
		pointer-events: none;

		img {
			object-fit: contain;

			&.blur-up {
				transition: filter 0.4s, transform 0.1s ease;
			}
		}

		@mixin mob-xl {
			width: 125.13vh;
			max-width: 883px;
			transform: translateX(-50%) translateY(-52%);
		}

		@mixin mob-lg {
			transform: translateX(-63%) translateY(-59%);
		}

		@mixin mob {
			transform: translateX(-63%) translateY(-56%);
		}
	}

	&__wrap {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
		width: 100%;
		position: relative;
		z-index: 5;
	}

	&__title {
		text-align: center;
		margin-bottom: ac(8px, 16px);
	}

	&__text {
		text-align: center;
		margin-bottom: ac(34px, 24px);
		max-width: ac(632px, 400px);
		width: 100%;

		@mixin mob-lg {
			max-width: 100%;
		}
	}

	&__container {
		padding-top: ac(56px, 32px);
		padding-bottom: ac(37px, 20px);
		padding-left: ac(100px, 24px);
		padding-right: ac(100px, 20px);
		width: 100%;
		max-width: ac(975px, 600px);
		background: linear-gradient(
			270deg,
			rgba(255, 255, 255, 0.15) 0%,
			rgba(0, 0, 0, 0.15) 100%
		);
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);
		backdrop-filter: blur(30px);
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;

		@mixin mob-xl {
			max-width: 100%;
			backdrop-filter: blur(20px);
		}

		.label-text {
			margin-right: ac(15px, 8px);
			margin-bottom: ac(14px, 12px);

			&.text-typing {
				width: 77px;
				position: relative;

				#typed {
					transition: opacity 0.25s ease;
					color: var(--black);
				}

				&.hidden-typed {
					span {
						opacity: 0;
					}
				}
			}

			&.with-full {
				@mixin mob-xl {
					width: 100%;
				}
			}
		}

		.contact-input {
			margin-right: ac(15px, 8px);
			margin-bottom: ac(14px, 12px);

			&.with-typing {
				position: absolute;
				inset: 0;
				z-index: 1;
				max-width: 100%;
				margin-left: 0;
				display: flex;
				align-items: center;
				margin-right: 0;
				margin-bottom: 0;

				input {
					width: 100%;
					opacity: 0;
					background: transparent;
					transition: opacity 0.25s ease;
					@mixin transparent-bg-input var(--white);

					&.active {
						opacity: 1;
					}
				}
			}

			&.with-last-name {
				input {
					width: ac(154px, 112px);
				}
			}

			&.with-message {
				input {
					width: ac(286px, 206px);
				}
			}

			&.with-email {
				input {
					width: ac(286px, 206px);
				}
			}
		}

		.checkboxes-contact {
			margin-top: ac(-5px, 0px);
			width: 100%;
			max-width: 560px;
			margin-bottom: ac(15px, 8px);
			@mixin mob-xl {
				max-width: 100%;
			}
		}
	}

	&__form {
		position: relative;
		transform: translateX(ac(-8px, 0px));

		.btn {
			position: absolute;
			bottom: 0;
			right: ac(100px, 20px);
			transform: translateY(50%);
			min-height: ac(54px, 48px);
		}
	}

	@mixin mob-xl {
		padding-bottom: 64px;
		background: var(--blue);
	}

	@mixin mob-lg {
		padding-top: 276px;
	}
}

.brief-file-input {
	width: 100%;
	align-items: center;
	justify-content: flex-start;
	display: none;

	&.active {
		display: flex;
	}

	.contact-input__placeholder {
		font-family: var(--font-main);
		font-size: ac(22px, 16px);
		font-weight: 400;
		line-height: ac(34px, 20px);
		color: var(--grey);
		border-radius: 0;
		border-bottom: 1px solid transparent;
		background: transparent;
		transition: all 0.25s ease;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
		flex: 1;

		span {
			max-width: 100%;
			@mixin max-line-length-one;
		}

		&:hover {
			color: var(--white);
		}
	}

	@mixin mob-xl {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}
}

.contact-map-section {
	background: var(--snow);
	padding-top: ac(264px, 120px);
	/*padding-bottom: ac(210px, 160px);*/
	padding-bottom: ac(80px, 40px);
	overflow: hidden;

	@mixin tab-md {
		padding-top: 180px;
	}

	@mixin mob {
		padding-bottom: 90px;
	}

	.cont {
		position: relative;
	}

	.line-title {
		position: absolute;
		top: ac(-102px, -84px);
		left: 0;
		z-index: 1;
		text-align: start;
		justify-content: flex-start;
		padding-left: 68.2%;

		@mixin desk-sm {
			padding-left: 62%;
		}

		@mixin tab-md {
			padding-left: 0;
			top: -110px;
		}
	}

	&__wrap {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		z-index: 2;

		@mixin tab-md {
			flex-direction: column;
		}
	}

	&__content {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		width: 31.8%;

		@mixin desk-sm {
			width: 38%;
		}

		@mixin tab-md {
			padding-top: 20px;
			width: 100%;
			order: 1;
			margin-bottom: 32px;

			@mixin min-media 552 {
				flex-wrap: wrap;
				flex-direction: row;

				.contact-map-section__content-item {
					width: 42%;
					margin-right: 20px;
				}
			}
		}
	}

	&__content-item {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;

		&:not(:last-child) {
			margin-bottom: ac(32px, 24px);
		}
	}

	&__content-title {
		font-size: ac(32px, 24px);
		font-weight: 500;
		line-height: ac(48px, 30px);
		margin-bottom: 16px;
	}

	&__content-link {
		font-size: ac(18px, 16px);
		font-weight: 400;
		line-height: ac(32px, 28px);
		transition: all 0.25s ease;

		&:hover {
			color: var(--dark-grey);
		}
	}

	&__socials {
		padding-top: ac(24px, 10px);
		.social-btn {
			background: var(--snow);
			margin-right: ac(16px, 12px);
			margin-left: 0;

			&:hover {
				background-color: var(--blue);
			}
		}
	}

	&__map {
		width: 57.34%;
		@mixin aspect-ratio 734, 625;
		border-width: 8px 1.5px;
		border-style: solid;
		border-color: var(--blue);
		box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.12);

		@mixin tab-md {
			width: 100%;
			order: 2;
		}
	}
}
