.testimonials-slider {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	overflow: visible;
	opacity: 0;
	transition: opacity 0.1s ease;
	user-select: none;

	.swiper-slide {
		height: auto;
	}

	&.swiper-initialized {
		opacity: 1;
	}
}

.testimonials-slider-buttons {
	margin-bottom: 5px;
	justify-content: center;
	.slider-btn {
		margin-top: 32px;

		&.swiper-button-lock {
			display: none;
		}
	}
}

.testimonials-slider-container {
	position: relative;
	width: 100%;
	max-width: ac(844px, 600px);
	margin: 0 auto;
	z-index: 1;

	.decor-testimonials-slider {
		overflow: visible;
		z-index: -1;
		position: absolute;
		inset: 0;

		.swiper-slide {
			height: auto;
		}

		&__item {
			background: #e8ecec;
			opacity: 0;
		}
	}

	@mixin tab-sm {
		max-width: 91%;
	}
}
