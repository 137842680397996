.benefits-section {
	background: var(--blue);
	padding-top: 142px;
	overflow: hidden;
	z-index: 4;
	padding-bottom: ac(127px, 60px);

	clip-path: polygon(0 0, 100% 0, 100% calc(100% - ac(127px, 60px)), 0 100%);

	@mixin min-media 1450 {
		clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8.82vw), 0 100%);
	}

	@mixin tab-sm {
		clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8.98vw), 0 100%);
	}

	@mixin min-media 1520 {
		padding-top: 162px;
	}

	.cont {
		position: relative;
	}

	.line-title {
		position: absolute;
		top: ac(-78px, -64px);
		left: 0;
		z-index: 1;
		text-align: left;
		justify-content: left;
		padding-left: ac(200px, 16px);
		padding-right: 16px;

		&__bg {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%) translateY(-99%);
			height: 300px;
			background: var(--snow);
			width: 200%;
		}
	}

	/*&__bottom {
		margin-top: ac(80px, 40px);
		display: block;
		width: 100%;
		@mixin aspect-ratio-block 1440, 127;
		overflow: visible;
		transform: scale(1.03);
		background: var(--black);
		z-index: 1;
		position: relative;

		&:after {
			position: absolute;
			inset: 0;
			content: "";
		}

		&:after {
			background: var(--blue);
			clip-path: polygon(0 0, 0% 100%, 100% 0);
			transform: scale(1.02);
		}
	}

	&.with-snow-bottom {
		.benefits-section__bottom {
			background: var(--snow);
		}
	}*/

	&__wrap {
		padding-bottom: ac(80px, 20px);
	}

	+ .our-team-section {
		z-index: 3;
		margin-top: ac(-128px, -61px);
		padding-top: ac(359px, 240px);

		@mixin min-media 1450 {
			margin-top: -8.84vw;
			padding-top: calc(232px + 8.82vw);
		}

		@mixin tab-sm {
			margin-top: -8.99vw;
			padding-top: calc(180 + 8.98vw);
		}
	}
}
