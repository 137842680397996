.footer {
	padding-top: ac(91px, 64px);
	/*background: var(--black);*/
	padding-bottom: 32px;
	z-index: 20;
	position: relative;

	&.second-type {
		background: var(--black);
	}

	&:before {
		top: 160px;
		left: 0;
		right: 0;
		bottom: 0;
		background: var(--black);
		position: absolute;
		content: "";
		z-index: -1;
	}

	&__top {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		padding-bottom: ac(88px, 40px);
		border-bottom: 1px solid color-mod(var(--white) a(25%));

		@mixin tab-md {
			flex-wrap: wrap;
			justify-content: center;
		}
	}

	&__logo {
		margin-top: ac(-10px, 0px);
		width: ac(162px, 140px);
		transition: transform 0.25s ease;

		.logo {
			width: 100%;
			@mixin aspect-ratio 162, 51;
			cursor: pointer;

			img {
				object-fit: contain;
				object-position: left;
			}

			object {
				pointer-events: none;
			}
		}

		@mixin tab-md {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 40px;

			.logo {
				width: 204px;
			}
		}

		&:hover {
			transform: scale(1.03);
		}
	}

	&__links {
		margin-top: ac(-4px, 0px);
		margin-left: ac(84px, 28px);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		a {
			color: var(--blue);

			&:hover {
				color: var(--white);
			}
		}

		@mixin tab-md {
			margin-left: 0;
		}

		@mixin tab-sm {
			width: 100%;
			align-items: center;
			margin-bottom: 56px;
		}
	}

	&__link {
		font-size: ac(22px, 20px);
		line-height: ac(34px, 24px);
		transition: all 0.25s ease;
		font-weight: 500;

		&:not(:last-child) {
			margin-bottom: ac(0px, 16px);
		}

		@mixin tab-md {
			font-size: 18px;
		}

		@mixin tab-sm {
			font-size: 20px;
		}
	}

	&__locations {
		margin-left: ac(94px, 30px);
		flex: 1;
		max-width: ac(324px, 220px);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		@mixin tab-md {
			margin-left: auto;
			margin-right: auto;
		}

		@mixin tab-sm {
			max-width: 163px;
			margin-left: 8px;
			margin-right: 8px;
			flex: none;
			align-items: center;
		}
	}

	&__location {
		font-family: var(--font-main);
		font-size: ac(18px, 14px);
		line-height: ac(32px, 17px);
		transition: all 0.25s ease;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		&:hover {
			color: var(--blue);
		}

		span {
			margin-bottom: ac(0px, 12px);
		}

		@mixin tab-sm {
			text-align: center;
			align-items: center;
		}

		@mixin mob-sm {
			font-size: 13px;
		}
	}

	&__nav {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		margin-left: auto;
		margin-right: ac(40px, 0px);

		@mixin tab-md {
			margin-right: 0;
		}

		@mixin tab-sm {
			margin-left: 8px;
			margin-right: 8px;
			align-items: center;
		}
	}

	&__nav-item {
		display: flex;
		&:not(:last-child) {
			margin-bottom: ac(0px, 12px);
		}
	}

	&__nav-link {
		font-family: var(--font-main);
		font-size: ac(18px, 14px);
		line-height: ac(32px, 17px);
		transition: color 0.25s ease;

		&:hover {
			color: var(--blue);
		}

		@mixin tab-sm {
			text-align: center;
		}

		@mixin mob-sm {
			font-size: 13px;
		}
	}

	&__bottom {
		padding-top: 22px;
		padding-bottom: ac(20px, 16px);
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@mixin media 1050 {
			flex-direction: column;
			justify-content: flex-start;
		}
	}

	&__bottom-content {
		p {
			font-size: 14px;
			line-height: 32px;

			br {
				display: none;

				@mixin mob {
					display: block;
				}
			}

			@mixin mob {
				font-size: 16px;
			}
		}

		text-align: center;

		@mixin media 1050 {
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
	}

	&__bottom-nav {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		@mixin mob-lg {
			justify-content: space-between;
			width: 100%;
		}
	}

	&__bottom-nav-link {
		font-family: var(--font-main);
		font-size: ac(16px, 14px);
		line-height: ac(32px, 24px);
		transition: all 0.25s ease;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		&:hover {
			color: var(--blue);
		}

		&:not(:last-child) {
			margin-right: ac(50px, 28px);

			@mixin mob {
				margin-right: 0;
			}
		}

		@mixin mob-lg {
			text-align: center;
		}

		@mixin mob {
			width: min-content;

			&:nth-child(1) {
				min-width: 70px;
			}

			&:nth-child(2) {
				min-width: 104px;
				transform: translateX(8px);

				@mixin mob-sm {
					transform: none;
				}
			}

			&:nth-child(3) {
				min-width: 91px;
			}
		}
	}

	&__developer {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	@mixin mob {
		padding-top: 34px;
	}
}
