.job-view-section {
	padding-top: 0;
	z-index: 10;
	overflow: visible;
	padding-bottom: ac(74px, 60px);

	&__wrap {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		position: relative;

		.team-card {
			position: sticky;
			margin-top: ac(-160px, -90px);
			top: 100px;
			margin-right: ac(48px, 0px);
			width: ac(292px, 240px);

			&__content {
				width: 100%;
			}

			@mixin desk-sm {
				margin-right: 0;
			}
		}

		@mixin tab-sm {
			flex-direction: column;
			justify-content: flex-start;

			.team-card {
				position: relative;
				margin-top: 24px;
				width: 280px;
				top: auto;
				margin-left: auto;
				margin-right: auto;
			}
		}

		@mixin mob {
			.team-card {
				width: 100%;
			}
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 65.86%;
		margin-top: ac(-20px, 0px);

		@mixin tab-md {
			width: 62%;
		}

		@mixin tab-sm {
			padding-top: 20px;
			width: 100%;
		}
	}

	&__content-top {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: ac(22px, 20px);

		@mixin mob {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
		}
	}

	&__published {
		font-size: ac(16px, 14px);
		font-weight: 400;
		line-height: ac(28px, 24px);

		strong {
			font-weight: 600;
		}

		@mixin mob {
			margin-top: 10px;
			margin-left: 0;
		}
	}

	&__article {
		width: 100%;

		&:not(:last-child) {
			padding-bottom: ac(48px, 24px);
		}
	}

	&__buttons {
		&:not(:last-child) {
			padding-bottom: ac(34px, 24px);
		}

		.btn {
			&:not(:last-child) {
				margin-right: ac(14px, 14px);
			}
		}
		@mixin mob-lg {
			width: 100%;
			flex-direction: column;

			.btn {
				width: 100%;
				text-align: center;

				&:not(:last-child) {
					margin-bottom: 16px;
					margin-right: 0;
				}
			}
		}
	}

	&__share {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		width: 100%;

		p {
			margin-right: ac(24px, 16px);
		}

		strong {
			font-weight: 700;
		}

		.social-btn {
			margin-left: 0;
			background: var(--snow);

			&:hover {
				background-color: var(--blue);
			}

			&:not(:last-child) {
				margin-right: ac(16px, 8px);
			}
		}
	}
}
